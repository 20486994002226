<template>
  <section class="home-nfts animation">
    <main class="section-main">
      <h1 class="section-heading">Game Ape NFT Collection</h1>
      <div class="content-wrap">
        <div class="content-1" @click="onFocus(1)">
          <img class="bg" src="@/assets/home/nfts-1.png" />
          <div class="inner">
            <h2 class="title">Discover, and collect extraordinary Game Ape NFTs</h2>
            <p class="desc">Learn more about the GAFC culture</p>
          </div>
          <img class="btn" src="@/assets/home/nft-more.png" />
        </div>
        <div class="content-2">
          <img class="bg" src="@/assets/home/nfts-2.png" />
          <div class="inner">
            <h2 class="title">Trending this month</h2>
            <NftsSlider></NftsSlider>
          </div>
        </div>
        <Ring class="content-3"></Ring>
        <div class="content-4" @click="onFocus(4)">
          <img class="bg" src="@/assets/home/nfts-4.png" />
          <img class="bg-i" src="@/assets/home/nfts-4i.gif" />
          <h2 class="title">Game Ape NFT Inspiration</h2>
          <div class="ball"></div>
          <img class="btn" src="@/assets/home/nft-more.png" />
        </div>
        <div class="content-5">
          <div class="center"></div>
          <div class="top" @click="onTop"></div>
          <div class="right" @click="onFocus(6)"></div>
          <div class="bottom" @click="onFocus(4)"></div>
          <div class="left" @click="onFocus(1)"></div>
        </div>
        <div class="content-6" @click="onFocus(6)">
          <img class="bg" src="@/assets/home/nfts-6.png" />
          <img class="box" src="@/assets/home/nfts-6-1.png" />
          <h2 class="title">Game Ape Holders’ Benefits</h2>
          <img class="btn" src="@/assets/home/nft-more.png" />
        </div>
        <a class="content-7" target="_blank" href="https://discord.gg/uwJxmcaPap">
          <img class="bg" src="@/assets/home/nfts-7.png" />
          <span class="text">
            Join our<br />
            Discord
          </span>
        </a>
      </div>
    </main>
    <NftsDialog
      class="nfts-dialog1"
      ref="dialog1"
      :page-count="3"
      :page="page"
      @prev="onPrev"
      @next="onNext"
    >
      <template v-if="page == 1">
        <Typed ref="typed11">
          <h2>Game Ape Fantasy Club Culture</h2>
          <p>We see ourselves as innovators, pioneers in turning concepts into reality.</p>
          <p>
            In the world of games, it should be open and everyone can feel free to join in and
            become whoever they want to be. This is the value of the club.
          </p>
        </Typed>
      </template>
      <template v-if="page == 2">
        <h2>Game Ape Fantasy Club Culture</h2>
        <Typed ref="typed12">
          <p>
            Game Ape NFTs not only represent the doppelgänger of a variety of classic game
            characters in different eras across the globe, but they are also a true reflection of
            these characters in the multiverse of Web3. We are looking to build a bridge between
            traditional games and blockchain games. With the advent and development of blockchain
            technology, everything is possible for the gaming industry.
          </p>
        </Typed>
      </template>
      <template v-if="page == 3">
        <h2>Game Ape Fantasy Club Culture</h2>
        <Typed ref="typed13">
          <p>
            There is a promising future ahead of the blockchain game industry, the club is working
            closely with virtual reality hardware manufacturers, tier 1 gaming platforms,
            traditional game developers, and game guilds, dedicated to creating a Metaverse game
            trend and lifestyle.
          </p>
        </Typed>
      </template>
    </NftsDialog>
    <NftsDialog4 ref="dialog4"></NftsDialog4>
    <NftsDialog
      class="nfts-dialog6"
      ref="dialog6"
      :image="require('@/assets/home/nfts-dialog-2.png')"
      :page-count="3"
      :page="page"
      @prev="onPrev"
      @next="onNext"
    >
      <template v-if="page == 1">
        <Typed ref="typed61">
          <h2>Game Ape Holders’ Benefits</h2>
          <p>Although Game Ape makes a rockin' PFP, we believe NFTs should have more utility.</p>
          <p>
            After launch, expect exclusive airdrops, members-only IRL events, and an array of
            brand-related products, merch & more…
          </p>
        </Typed>
      </template>
      <template v-if="page == 2">
        <h2>Game Ape Holders’ Benefits</h2>
        <ul class="list">
          <Typed ref="typed62">
            <li>Eligible for exclusive NFT airdrops in the future</li>
            <li>NFT airdrops from Game Ape DAO genesis members</li>
            <li>IRL activities - Early access to the offline art exhibition and event</li>
            <li>Exclusive merch: Clothing, garage kit, game consoles</li>
            <li>Exclusive Metaverse Space shared with BAYC holders</li>
          </Typed>
        </ul>
      </template>
      <template v-if="page == 3">
        <h2>Game Ape Holders’ Benefits</h2>
        <Typed ref="typed63">
          <p>
            Apart from that, Game Ape NFT is a ticket to a super cool community built by a number of
            BAYC holders. You have a great opportunity to get in touch with BAYC members without
            joining the yacht club. In the meantime, it is also about identity - every member can
            find their place in the club, they could be the most cutting-edge Metaverse players,
            besides, they could be community opinion leaders, alternatively, they could also be
            partners of the club and its members.
          </p>
        </Typed>
      </template>
    </NftsDialog>
  </section>
</template>

<script>
import Typed from '@/components/Typed.vue'
import NftsSlider from './NftsSlider.vue'
import NftsDialog from './NftsDialog.vue'
import NftsDialog4 from './NftsDialog4.vue'
import Ring from './Ring'

export default {
  name: 'HomeNfts',
  components: {
    Typed,
    NftsSlider,
    NftsDialog,
    NftsDialog4,
    Ring,
  },
  data() {
    return {
      max: 3,
      page: 1,
    }
  },
  methods: {
    onFocus(id) {
      this.$refs[`dialog${id}`].show()
      this._id = id
      this.page = 1
    },
    onTop() {
      window.scrollTo(0, 0)
    },

    onPrev() {
      const typed = this.$refs[`typed${this._id}${this.page}`]
      if (!typed.isCompleted()) {
        typed.complete()
      } else {
        this.page -= 1
      }
    },
    onNext() {
      const typed = this.$refs[`typed${this._id}${this.page}`]
      if (!typed.isCompleted()) {
        typed.complete()
      } else {
        this.page += 1
      }
    },
  },
}
</script>

<style lang="scss">
.home-nfts {
  margin-top: 6rem;
  line-height: 1.4;
  font-family: 'Gotham-Book';
  font-size: 0.7rem;
  @media (max-width: 768.89px) {
    margin: 3rem 1rem;
  }
  .section-heading {
    transition: transform 500ms, opacity 500ms;
  }
  .content-wrap {
    margin-top: 1.2rem;
    padding-top: 70%;
    position: relative;
    // transition: transform 500ms, opacity 500ms;
    .bg {
      display: block;
      width: 100%;
    }
  }
  .content-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 50.9765%;
    padding-top: 24.25%;
    padding-bottom: 7%;
    transition: transform 500ms, opacity 500ms;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 1ms 1s;
    }
    .inner {
      position: absolute;
      top: 30%;
      left: 55%;
      right: 5%;
      bottom: 15%;
      overflow: hidden;
      transition: top 1ms 1s, left 1s;
    }
    .title {
      font-family: 'Cansiparane-Bold';
      font-size: 1rem;
      color: #36edff;
    }
    .desc {
      margin: 1rem 0 0.7rem;
    }
    .btn {
      position: absolute;
      bottom: 4%;
      right: 5%;
      display: block;
      width: 3rem;
      cursor: pointer;
    }
  }
  .content-2 {
    position: absolute;
    top: 49.6%;
    left: 0;
    width: 50.9765%;
    z-index: 1;
    transition: transform 500ms, opacity 500ms;
    .inner {
      position: absolute;
      top: 4%;
      left: 6%;
      right: 6%;
      bottom: 6%;
      overflow: hidden;
    }
    .title {
      margin: 0 0 0.1rem 0;
      font-family: 'Cansiparane-Bold';
      font-size: 1rem;
      color: #000;
    }
  }
  .content-3 {
    position: absolute;
    top: 10%;
    left: 51%;
    width: 15.039%;
    transition: transform 500ms, opacity 500ms;
  }
  .content-4 {
    position: absolute;
    top: 31.5%;
    left: 53%;
    width: 25.195%;
    padding-top: 42.48%;
    color: #000;
    transition: transform 500ms, opacity 500ms;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 1ms 1s;
    }
    .bg-i {
      position: absolute;
      top: 3%;
      left: 3%;
      width: 40%;
      transition: opacity 1ms 1s;
    }
    .title {
      position: absolute;
      top: 36%;
      left: 10%;
      right: 10%;
      z-index: 1;
      font-family: 'Cansiparane-Bold';
      font-size: 1rem;
    }
    .ball {
      position: absolute;
      top: 54%;
      left: 13%;
      width: 71.317%;
      padding-top: 55.038%;
      background: url('~@/assets/home/nfts-4-1.png') 0 0 no-repeat;
      background-size: 100%;
      animation: ball-twinkle 2s linear infinite;
      @keyframes ball-twinkle {
        0% {
          background-image: url('~@/assets/home/nfts-4-1.png');
        }
        50% {
          background-image: url('~@/assets/home/nfts-4-1a.png');
        }
      }
    }
    .btn {
      position: absolute;
      bottom: 3%;
      right: 10%;
      display: block;
      width: 3rem;
      cursor: pointer;
      transition: opacity 1ms 1s;
    }
  }
  .content-5 {
    position: absolute;
    top: 31%;
    left: 64.8%;
    width: 13.4765%;
    padding-top: 13.4765%;
    transition: transform 500ms, opacity 500ms;
    .center {
      position: absolute;
      top: 37.5%;
      left: 37.5%;
      width: 25%;
      padding-top: 25%;
      background: url('~@/assets/home/nfts-5-c.png') 0 0 no-repeat;
      background-size: cover;
    }
    .top {
      position: absolute;
      top: 3.125%;
      left: 30.625%;
      width: 38.75%;
      padding-top: 35%;
      background: url('~@/assets/home/nfts-5-t.png') 0 0 no-repeat;
      background-size: cover;
      &:hover {
        background-image: url('~@/assets/home/nfts-5-ta.png');
      }
    }
    .bottom {
      position: absolute;
      bottom: 3.125%;
      left: 30.625%;
      width: 38.75%;
      padding-top: 35%;
      background: url('~@/assets/home/nfts-5-b.png') 0 0 no-repeat;
      background-size: cover;
      &:hover {
        background-image: url('~@/assets/home/nfts-5-ba.png');
      }
    }
    .right {
      position: absolute;
      top: 30.625%;
      right: 3.125%;
      width: 35%;
      padding-top: 38.75%;
      background: url('~@/assets/home/nfts-5-r.png') 0 0 no-repeat;
      background-size: cover;
      &:hover {
        background-image: url('~@/assets/home/nfts-5-ra.png');
      }
    }
    .left {
      position: absolute;
      top: 30.625%;
      left: 3.125%;
      width: 35%;
      padding-top: 38.75%;
      background: url('~@/assets/home/nfts-5-l.png') 0 0 no-repeat;
      background-size: cover;
      &:hover {
        background-image: url('~@/assets/home/nfts-5-la.png');
      }
    }
  }
  .content-6 {
    position: absolute;
    top: 10%;
    left: 79.59%;
    width: 20.41%;
    padding-top: 45.703%;
    color: #000;
    transition: transform 500ms, opacity 500ms;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: opacity 1ms 1s;
    }
    .box {
      position: absolute;
      top: 5%;
      left: 8%;
      width: 84.688%;
    }
    .title {
      position: absolute;
      top: 10%;
      left: 10%;
      right: 10%;
      z-index: 1;
      font-family: 'Cansiparane-Bold';
      font-size: 1rem;
    }
    .btn {
      position: absolute;
      bottom: 4%;
      right: 10%;
      display: block;
      width: 3rem;
      cursor: pointer;
      transition: opacity 1ms 1s;
    }
  }
  .content-7 {
    position: absolute;
    top: 84%;
    left: 80.5%;
    display: block;
    width: 18.457%;
    line-height: 1.1;
    font-family: 'Cansiparane-Bold';
    font-size: 1rem;
    color: inherit;
    transition: transform 500ms, opacity 500ms;
    @media (max-width: 768.89px) {
      font-size: 0.75rem;
    }
    .text {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 60%;
      text-align: left;
      @media (max-width: 768.89px) {
        right: 2%;
      }
    }
  }
  .nfts-dialog {
    h2 {
      font-family: 'Gotham-Bold';
      font-size: 1.1rem;
    }
    p {
      margin: 0.8rem 0;
    }
    .list {
      margin: 0.5rem 0;
      padding-left: 1rem;
      li {
        list-style: inside;
      }
    }
  }

  &.animation {
    .section-heading {
      opacity: 0;
    }
    // .content-wrap {
    //   transform: translateY(80%) translateZ(0);
    //   opacity: 0;
    // }
    .content-1,
    .content-2,
    .content-3,
    .content-4,
    .content-5,
    .content-6,
    .content-7 {
      transform: translateY(80%) translateZ(0);
      opacity: 0;
    }
  }
}
</style>
