<template>
  <div class="page-home">
    <Nav></Nav>
    <Welcome class="home-section"></Welcome>
    <Nfts class="home-section"></Nfts>
    <Activity class="home-section"></Activity>
    <Partners class="home-section"></Partners>
    <Faq class="home-section"></Faq>
    <Footer></Footer>
    <a :class="['home-top', topVisible ? 'visible' : '']" @click="onTop"></a>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Welcome from './Welcome.vue'
import Nfts from './Nfts.vue'
import Activity from './Activity.vue'
import Partners from './Partners.vue'
import Faq from './Faq.vue'

class EnterAnimation {
  static observers = []

  static watch() {
    EnterAnimation.observers = []

    const animateElements = document.querySelectorAll('.page-home > .animation')
    animateElements.forEach((elem) => {
      const observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0]
          if (entry.isIntersecting) {
            elem.classList.remove('animation')
            observer.disconnect()
          }
        },
        {
          threshold: 0.3,
        }
      )
      observer.observe(elem)
      EnterAnimation.observers.push(observer)
    })
  }
  static clear() {
    EnterAnimation.observers.forEach((observer) => {
      observer.disconnect()
    })
    EnterAnimation.observers = []
  }
}

export default {
  components: {
    Nav,
    Footer,
    Welcome,
    Nfts,
    Activity,
    Partners,
    Faq,
  },
  data() {
    return {
      topVisible: false,
    }
  },
  methods: {
    onTop() {
      window.scrollTo(0, 0)
    },
    _onTopVisible() {
      const canVisible = document.documentElement.scrollTop > document.documentElement.clientHeight
      if (canVisible != this.topVisible) {
        this.topVisible = canVisible
      }
    },
  },
  mounted() {
    EnterAnimation.watch()
    window.addEventListener('scroll', this._onTopVisible)
  },
  unmounted() {
    EnterAnimation.clear()
    window.removeEventListener('scroll', this._onTopVisible)
  },
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Cansiparane-Bold';
  src: url('~@/assets/fonts/Cansiparane-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham-Bold';
  src: url('~@/assets/fonts/Gotham-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham-Book';
  src: url('~@/assets/fonts/Gotham-Book.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham-Light';
  src: url('~@/assets/fonts/Gotham-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Gotham-Medium';
  src: url('~@/assets/fonts/Gotham-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Radioland-Slim';
  src: url('~@/assets/fonts/Radioland-Slim.ttf') format('truetype');
}
@font-face {
  font-family: 'Radioland';
  src: url('~@/assets/fonts/Radioland.ttf') format('truetype');
}
@font-face {
  font-family: 'Krungthep';
  src: url('~@/assets/fonts/Krungthep.ttf') format('truetype');
}
@font-face {
  font-family: 'Led';
  src: url('~@/assets/fonts/Led.ttf') format('truetype');
}
@font-face {
  font-family: 'Pixeboy';
  src: url('~@/assets/fonts/Pixeboy.ttf') format('truetype');
}

.page-home {
  background-color: #000;
  color: #fff;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  .home-section {
    position: relative;
    text-align: left;
    .section-heading {
      line-height: 2.5rem;
      font-family: 'Cansiparane-Bold';
      font-size: 1.5rem;
      font-weight: bold;
    }
    .section-main {
      margin: 0 auto;
      max-width: 51.2rem;
    }
  }
  .home-top {
    display: none;
    position: fixed;
    z-index: 6;
    right: 1.5rem;
    bottom: 20%;
    width: 2.5rem;
    height: 2.5rem;
    background: url('~@/assets/home/side-top.png') 0 0 no-repeat;
    background-size: 100%;
    cursor: pointer;
    &:hover {
      background-image: url('~@/assets/home/side-top-a.png');
    }
    &.visible {
      display: block;
    }
  }
}
</style>
