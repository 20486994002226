<template>
  <section :class="['faq-section', active ? 'active' : '']" @click="onToggle">
    <div class="icon" v-if="image"><img :src="image" /></div>
    <div class="head">
      <span class="title">
        <slot name="title"></slot>
      </span>
    </div>
    <div class="desc">
      <slot name="desc"></slot>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    image: String,
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    onToggle() {
      this.active = !this.active
    },
  },
}
</script>

<style lang="scss" scoped>
.faq-section {
  position: relative;
  margin: 0 0 2.2rem 1rem;
  padding: 0 1.5rem 0 4rem;
  background: linear-gradient(90deg, #0018bc 0%, #0b1664 100%);
  transition: margin 1s, padding 1s, box-shadow 200ms;
  cursor: pointer;

  .icon {
    position: absolute;
    top: -0.4rem;
    left: -1rem;
    padding: 0.05rem;
    width: 3.3rem;
    height: 3.3rem;
    border: 0.1rem solid transparent;
    border-radius: 3rem;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to bottom, #fff, #fff),
      linear-gradient(180deg, #8f41e9, #578aef);
    overflow: hidden;
    transition: top 1s, left 1s;
    img {
      border: 0 none;
      border-radius: 3rem;
      width: 100%;
      height: 100%;
    }
  }
  .head {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    line-height: 1.5;
    font-family: 'Gotham-Bold';
    font-size: 1rem;
    color: #66ffbd;
    .title {
      display: block;
      flex: 1;
    }
    &::after {
      content: '';
      display: block;
      width: 1.1rem;
      height: 0.6rem;
      background: url('~@/assets/home/faq-arrow.png') 0 0 no-repeat;
      background-size: 100%;
      transform: rotate(180deg);
      transition: transform 1s;
    }
  }
  .desc {
    max-height: 0;
    line-height: 1.5;
    font-family: 'Gotham-Book';
    font-size: 0.8rem;
    color: #d6d6d6;
    overflow: hidden;
    transition: max-height 1s, padding 1s;
  }
  &:hover {
    box-shadow: 0 0 0.5rem 0.3rem inset #2e4cff;
  }
  &.active {
    margin-left: 0;
    padding: 1rem 1.5rem 0 6rem;
    box-shadow: 0 0 0.5rem 0.3rem inset #2e4cff;
    .icon {
      top: 1rem;
      left: 1.2rem;
    }
    .head {
      border-bottom: 0.05rem solid #1931db;
      &::after {
        transform: none;
      }
    }
    .title {
      &::after {
        transform: rotate(180deg);
      }
    }
    .desc {
      padding: 1rem 0;
      max-height: 10rem;
    }
  }
}
</style>
