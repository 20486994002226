<template>
  <div ref="typed">
    <slot></slot>
  </div>
</template>

<script>
import Typed from 'typed.js'

function typing(nodes, index = 0, wrapElem) {
  if (!nodes || index >= nodes.length || !wrapElem) {
    typing._isCompleted = true
    typing._instance && typing._instance.destroy()
    return
  }
  typing._isCompleted = false

  const node = nodes[index]

  let outerElem = wrapElem
  let text = ''

  // 参考文档：https://developer.mozilla.org/zh-CN/docs/Web/API/Node/nodeType
  if (node.nodeType == Node.ELEMENT_NODE) {
    text = node.innerHTML.trim()
    outerElem = document.createElement(node.nodeName)
    wrapElem.appendChild(outerElem)
  } else if (node.nodeType == Node.TEXT_NODE) {
    text = node.data.trim()
  }

  // 当前节点没有文本，直接进入下一节点
  if (!text) {
    typing(nodes, index + 1, wrapElem)
    return
  }

  const elem = document.createElement('span')
  outerElem.appendChild(elem)

  typing._instance = new Typed(elem, {
    strings: [text],
    typeSpeed: 10,
    onComplete() {
      if (index < nodes.length) {
        wrapElem.removeChild(wrapElem === outerElem ? elem : outerElem)
        wrapElem.appendChild(node.cloneNode(true))
        typing(nodes, index + 1, wrapElem)
      } else {
        // 触发完成事件
        typing._isCompleted = true
      }
    },
  })
}

export default {
  props: {},
  methods: {
    complete() {
      typing()
      const typedElem = this.$refs.typed
      typedElem.innerHTML = this._shadowElem.innerHTML
    },
    isCompleted() {
      return typing._isCompleted
    },
  },
  mounted() {
    const typedElem = this.$refs.typed
    const tempElem = typedElem.cloneNode(true)
    this._shadowElem = tempElem
    typedElem.innerHTML = ''

    typing(tempElem.childNodes, 0, typedElem)
  },
}
</script>
