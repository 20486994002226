<template>
  <div class="page-terms">
    <Nav></Nav>
    <main class="terms-main">
      <h1 class="h1">Terms</h1>
      <section class="section">
        <p class="p">
          Game Ape Fantasy Club is a collection of digital artworks (NFTs) running on the Ethereum
          network. This website is only an interface allowing participants to exchange digital
          collectibles. Users are entirely responsible for the safety and management of their own
          private Ethereum wallets and validating all transactions and contracts generated by this
          website before approval. Furthermore, as the Game Ape Fantasy Club smart contract runs on
          the Ethereum network, there is no ability to undo, reverse, or restore any transactions.
        </p>
        <p class="p">
          This website and its connected services are provided “as is” and “as available” without a
          warranty of any kind. By using this website you are accepting sole responsibility for any
          and all transactions involving Game Ape Fantasy Club digital collectibles.
        </p>
      </section>
      <h2 class="h2">OWNERSHIP</h2>
      <section class="section">
        <ol class="ol">
          <li class="p">
            You Own the NFT. Each Game Ape is an NFT on the Ethereum blockchain. When you purchase
            an NFT, you own the underlying Game Ape, the Art, completely. Ownership of the NFT is
            mediated entirely by the Smart Contract and the Ethereum Network: at no point may we
            seize, freeze, or otherwise modify the ownership of any Game Ape.
          </li>
          <li class="p">
            Personal Use. Subject to your continued compliance with these Terms, Game Ape DAO grants
            you a worldwide, royalty-free license to use, copy, and display the purchased Art, along
            with any extensions that you choose to create or use, solely for the following purposes:
            (i) for your own personal, non-commercial use; (ii) as part of a marketplace that
            permits the purchase and sale of your Game Ape / NFT, provided that the marketplace
            cryptographically verifies each Game Ape owner’s rights to display the Art for their
            Game Ape to ensure that only the actual owner can display the Art; or (iii) as part of a
            third-party website or application that permits the inclusion, involvement, or
            participation of your Game Ape, provided that the website/application cryptographically
            verifies each Game Ape owner’s rights to display the Art for their Game Ape to ensure
            that only the actual owner can display the Art, and provided that the Art is no longer
            visible once the owner of the Game Ape leaves the website/application.
          </li>
          <li class="p">
            Commercial Use. Subject to your continued compliance with these Terms, Yuga Labs LLC
            grants you an unlimited, worldwide license to use, copy, and display the purchased Art
            for the purpose of creating derivative works based upon the Art (“Commercial Use”).
            Examples of such Commercial Use would e.g. be the use of the Art to produce and sell
            merchandise products (T-Shirts etc.) displaying copies of the Art. For the sake of
            clarity, nothing in this Section will be deemed to restrict you from (i) owning or
            operating a marketplace that permits the use and sale of Game Apes generally, provided
            that the marketplace cryptographically verifies each Game Ape owner’s rights to display
            the Art for their Game Ape to ensure that only the actual owner can display the Art;
            (ii) owning or operating a third-party website or application that permits the
            inclusion, involvement, or participation of Game Apes generally, provided that the
            third-party website or application cryptographically verifies each Game Ape owner’s
            rights to display the Art for their Game Ape to ensure that only the actual owner can
            display the Art, and provided that the Art is no longer visible once the owner of the
            Purchased Game Ape leaves the website/application; or (iii) earning revenue from any of
            the foregoing.
          </li>
        </ol>
      </section>
    </main>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  components: {
    Nav,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss">
.page-terms {
  background-color: #000;
  color: #fff;
  .terms-main {
    margin: 0 auto;
    padding: 0.5rem 0 2rem;
    max-width: 51.2rem;
    line-height: 1.5;
    font-family: 'Gotham-Book';
    font-size: 0.8rem;
    @media (max-width: 768.89px) {
      margin: 0 1.5rem;
    }
  }
  .section {
    margin-bottom: 5rem;
    padding: 0.5rem 0;
    border-top: 0.05rem solid #66ffbd;
    border-bottom: 0.05rem solid #66ffbd;
  }
  .h1,
  .h2 {
    margin: 2rem 0;
    line-height: 1.2;
    font-family: 'Cansiparane-Bold';
    color: #66ffbd;
  }
  .h1 {
    font-size: 3.5rem;
  }
  .h2 {
    font-size: 1.5rem;
  }
  .p {
    margin: 1.8rem 0;
  }
  .ol {
    list-style: decimal;
    padding-left: 2rem;
  }
}
</style>
