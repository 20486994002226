<template>
  <section class="home-faq animation" id="faq">
    <main class="section-main">
      <h1 class="section-heading">FAQ</h1>
      <div class="content-wrap">
        <FaqItem class="faq-item" :image="require('@/assets/home/faq-1.png')">
          <template v-slot:title> 1. When is the launch of the Game Ape Collection? </template>
          <template v-slot:desc>
            To be announced soon. Follow us on Twitter and join our Discord to stay up to date.
          </template>
        </FaqItem>
        <FaqItem class="faq-item" :image="require('@/assets/home/faq-2.png')">
          <template v-slot:title> 2. What is the mint price? </template>
          <template v-slot:desc> To be announced soon. </template>
        </FaqItem>
        <FaqItem class="faq-item" :image="require('@/assets/home/faq-3.png')">
          <template v-slot:title> 3. How do I get on the allowlist? </template>
          <template v-slot:desc>
            <p>
              Also known as a "mint whitelist", NFT whitelisting is the process of getting your
              wallet address pre-approved for minting in order to get access to the mint before the
              Public round.
            </p>
            <p>
              To get whitelisted, please join our Discord and read #allowlist_info for further
              instructions. Community members will be rewarded for their contributions.
            </p>
          </template>
        </FaqItem>
        <FaqItem class="faq-item" :image="require('@/assets/home/faq-4.png')">
          <template v-slot:title> 4. Which secondary markets will you be listed on? </template>
          <template v-slot:desc>
            We are applying to the major marketplaces first before moving onto smaller marketplaces.
          </template>
        </FaqItem>
      </div>
    </main>
  </section>
</template>

<script>
import FaqItem from './FaqItem.vue'

export default {
  components: {
    FaqItem,
  },
}
</script>

<style lang="scss">
.home-faq {
  margin: 4rem 0 6rem;
  font-family: 'Cansiparane-Bold';
  @media (max-width: 768.89px) {
    margin: 2rem 1rem 4rem;
  }
  .section-heading {
    transition: transform 500ms, opacity 500ms;
  }
  .content-wrap {
    margin-top: 2.5rem;
    transition: transform 500ms, opacity 500ms;
  }
  // .faq-item {
  //   transition: transform 500ms, opacity 500ms;
  //   &:nth-child(1) {
  //     transition-delay: 0, 0;
  //   }
  //   &:nth-child(2) {
  //     transition-delay: 100ms, 100ms;
  //   }
  //   &:nth-child(3) {
  //     transition-delay: 200ms, 200ms;
  //   }
  //   &:nth-child(4) {
  //     transition-delay: 300ms, 300ms;
  //   }
  // }
  p {
    margin-bottom: 1rem;
  }

  &.animation {
    .section-heading {
      opacity: 0;
    }
    .content-wrap {
      transform: translateY(80%) translateZ(0);
      opacity: 0;
    }
    // .faq-item {
    //   transform: translateX(50%) translateZ(0);
    //   opacity: 0;
    // }
  }
}
</style>
