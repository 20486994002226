/**
 * 将钱包地址转换为带***的格式
 * @param {String} address 钱包地址
 */
export function transformAddress(address) {
  return address ? `${address.slice(0, 4)}******${address.slice(-4)}` : ''
}

/**
 * 创建倒计时
 * @param {Number} leftTime 剩余时间，单位：s
 * @param {Function} callback 回调函数
 */
export function createTimer(leftTime, callback) {
  const timeout = setInterval(() => {
    leftTime--
    if (!(leftTime > 0)) {
      clearInterval(timeout)
      callback([], leftTime)
      return
    }

    const time = [
      parseInt(leftTime / 60 / 60 / 24), // day
      parseInt((leftTime / 60 / 60) % 24), // hour
      parseInt((leftTime / 60) % 60), // minute
      parseInt(leftTime % 60), // second
    ].map((t) => (t < 100 ? `0${t}`.slice(-2) : `${t}`))

    callback(time, leftTime)
  }, 1000)

  return {
    stop() {
      clearInterval(timeout)
    },
  }
}

/** 伦敦时间 */
export const TIME = '2022-01-21 8:00:00'

/**
 * 获取剩余时间
 */
export function getLeftTime() {
  const now = new Date()
  const offset = now.getTimezoneOffset() * 60000
  const left = (Date.parse(TIME) - offset - now) / 1000
  return left
}

/**
 * 日期时间格式化
 * @param {String} format 指定的格式：YYYY-MM-DD hh:mm:ss
 * @param {Date} date
 */
export function dateFormat(format, date) {
  let ret
  const options = {
    'Y+': date.getFullYear().toString(), // 年
    'M+': (date.getMonth() + 1).toString(), // 月
    'D+': date.getDate().toString(), // 日
    'h+': date.getHours().toString(), // 时
    'm+': date.getMinutes().toString(), // 分
    's+': date.getSeconds().toString(), // 秒
  }
  for (let key in options) {
    ret = new RegExp('(' + key + ')').exec(format)
    if (ret) {
      format = format.replace(
        ret[1],
        ret[1].length == 1 ? options[key] : options[key].padStart(ret[1].length, '0')
      )
    }
  }
  return format
}

/**
 * 获取变量的类型，参数可以是任何类型的变量
 * @param  {Object} obj
 * @return {String}
 */
export function type(obj) {
  return obj == null ? obj + '' : toString.call(obj).slice(8, -1).toLowerCase()
}
