<template>
  <div class="slider" @touchstart.prevent.stop="onTouchStart" @touchend.prevent.stop="onTouchEnd">
    <div
      v-for="(item, index) in list"
      :key="item.id"
      class="slider-item"
      :style="item.style"
      @click="onItem(item, index)"
    >
      <!-- <div class="num">No.{{ item.id }}</div> -->
      <div class="img-wrap">
        <img class="img" :src="item.img" />
      </div>
      <div class="info">
        <h3 class="name">{{ item.name }}</h3>
        <p class="desc">{{ item.desc }}</p>
      </div>
    </div>
  </div>
  <div class="slider-handle">
    <a class="slider-prev" @click="onPrev"></a>
    <a class="slider-next" @click="onNext"></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],

      current: 0,
    }
  },
  methods: {
    onTouchStart(evt) {
      const touches = evt.changedTouches
      const touch = touches[0]
      this._start = {
        pageX: touch.pageX,
        pageY: touch.pageY,
      }
    },
    onTouchEnd(evt) {
      const touches = evt.changedTouches
      const touch = touches[0]
      const end = {
        pageX: touch.pageX,
        pageY: touch.pageY,
      }
      const start = this._start
      const vDistance = Math.abs(end.pageY - start.pageY)
      const hDistance = end.pageX - start.pageX
      if (vDistance <= 20 && hDistance >= 40) {
        this.onPrev()
      } else if (vDistance <= 20 && hDistance <= -40) {
        this.onNext()
      }
    },

    onItem(item, index) {
      console.log('--------', item, index)
    },
    onPrev() {
      this.prev()
      this.stop()
      this.auto()
    },
    onNext() {
      this.next()
      this.stop()
      this.auto()
    },

    slideTo(current) {
      this.current = current
      const list = this.list || []
      const length = list.length
      list.forEach((nft) => {
        nft.style = 'display: none'
      })
      ;[0, -1, 1, -2, 2, -3, 3].forEach((diff) => {
        const absDiff = Math.abs(diff)
        const index = (current + diff + length) % length
        const nft = list[index]
        nft.style = [
          `z-index: ${10 - absDiff}`,
          `left: ${50 + diff * 25}%`,
          'transform: translate(-50%) ' + (diff == 0 ? '' : `scale(${1 - absDiff * 0.125})`),
        ].join('; ')
      })
    },
    next() {
      const length = this.list.length
      const current = this.current
      this.slideTo((current + 1) % length)
    },
    prev() {
      const length = this.list.length
      const current = this.current
      this.slideTo((current + length - 1) % length)
    },

    auto() {
      const list = this.list || []
      if (list.length < 5) return

      clearTimeout(this._autoTimeout)
      this._autoTimeout = setTimeout(() => {
        this.next()
        this.auto()
      }, 3000)
    },
    stop() {
      clearTimeout(this._autoTimeout)
    },

    _pageVisibilityChange() {
      // 页面变为不可见时触发
      if (document.visibilityState == 'hidden') {
        this.stop()
      }
      // 页面变为可见时触发
      if (document.visibilityState == 'visible') {
        this.auto()
      }
    },

    _getList() {
      const url = '/nft/list'
      return this.$axios(url)
        .then((res) => {
          return (res || []).map((item) => ({
            id: item.order,
            img: item.src,
            name: `0x${item.role}` || '',
            desc: `0x${item.series}` || '',
            style: 'display: none',
          }))
        })
        .catch((err) => {
          console.error(err)
          return []
        })
    },
  },
  async mounted() {
    const list = await this._getList()
    this.list = list

    if (list && list.length >= 5) {
      this.slideTo(0)
      this.auto()
    }

    document.addEventListener('visibilitychange', this._pageVisibilityChange)
  },
  unmounted() {
    this.stop()
    document.removeEventListener('visibilitychange', this._pageVisibilityChange)
  },
}
</script>

<style lang="scss" scoped>
.slider {
  position: relative;
  height: 11rem;
}
.slider-item {
  position: absolute;
  margin-top: 0.9rem;
  width: 7.5rem;
  color: #020202;
  font-size: 0.7rem;
  transition: left 1s, transform 1s;
  @media (max-width: 768.89px) {
    margin-top: 0.7rem;
    width: 5rem;
  }
  .num {
    position: absolute;
    top: -0.9rem;
    right: 0;
    display: inline-block;
    padding: 0 0.4rem;
    line-height: 0.9rem;
    text-align: center;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background-color: #fff;
    font-family: 'Cansiparane-Bold';
    @media (max-width: 768.89px) {
      top: -0.7rem;
      padding: 0 0.3rem;
      line-height: 0.7rem;
      font-size: 0.8em;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
    }
  }
  .img-wrap {
    display: block;
    width: 100%;
    height: 7.5rem;
    line-height: 7.5rem;
    text-align: center;
    background-color: #888;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    overflow: hidden;
    @media (max-width: 768.89px) {
      height: 5rem;
      line-height: 5rem;
    }
  }
  .img {
    max-width: 100%;
    max-height: 100%;
  }
  .info {
    padding: 0.2rem 0.5rem;
    line-height: 1.3;
    background-color: #fff;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    @media (max-width: 768.89px) {
      padding: 0.2rem 0.3rem;
    }
  }
  .name {
    font-family: 'Gotham-Bold';
    font-size: inherit;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .desc {
    font-family: 'Gotham-Book';
    font-size: 0.8em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.slider-handle {
  margin-top: 0.5rem;
  text-align: center;
  .slider-prev,
  .slider-next {
    margin: 0 0.5rem;
    display: inline-block;
    width: 1rem;
    height: 0;
    border-top: 0.35rem solid transparent;
    border-bottom: 0.35rem solid transparent;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }
  .slider-prev {
    border-right: 0.5rem solid #fff;
  }
  .slider-next {
    border-left: 0.5rem solid #fff;
  }
}
</style>
