<template>
  <nav class="comp-nav">
    <main class="nav-main">
      <a class="logo-wrap" href="/">
        <img class="logo" src="@/assets/nav/logo.png" />
      </a>
      <div :class="['menu-wrap', active ? 'active' : '']">
        <img class="bg" src="@/assets/nav/menu.png" />
        <div class="info">
          <!-- <div class="item" style="flex: 7">
            <img class="icon" src="@/assets/nav/icon-eth.png" />
            <span class="text">{{ eth }}</span>
            <div class="tips">Your ETH</div>
          </div> -->
          <div class="item sui" style="flex: 7">
            <img class="icon" src="@/assets/nav/icon-sui.png" />
            <span class="text">{{ sui }}</span>
            <div class="tips">Your Sui</div>
          </div>
          <div class="item">
            <img class="icon" src="@/assets/nav/icon-nft.png" />
            <span class="text">{{ nft }}</span>
            <div class="tips">Your NFT</div>
          </div>
        </div>
        <div
          class="account"
          @mouseenter="onHover(true)"
          @mouseleave="onHover(false)"
          @touchstart="onHover()"
        >
          <div class="text">GAME APE</div>
          <div class="wallet">{{ $root.vWalletAddr || '******' }}</div>
        </div>
        <div
          class="avatar"
          @mouseenter="onHover(true)"
          @mouseleave="onHover(false)"
          @touchstart="onHover()"
        ></div>
        <div class="social">
          <a
            v-for="item in social"
            :key="item.name"
            :class="['social-item', `social-${item.name}`]"
            target="_blank"
            :href="item.link"
          >
            <img :src="item.image2" />
          </a>
        </div>
        <ul
          class="list"
          @mouseenter="onHover(true)"
          @mouseleave="onHover(false)"
          @touchstart="onHover()"
        >
          <li class="item">
            <a class="text" @click="onConnect">Connect Wallet</a>
          </li>
          <li class="item">
            <a class="text" style="padding-left: 3rem" @click="onMint">Mint</a>
          </li>
          <li class="item">
            <a class="text" @click="onFaq">FAQs</a>
          </li>
        </ul>
      </div>
    </main>
  </nav>
  <ConnectDialog ref="ConnectDialog"></ConnectDialog>
</template>

<script>
import { JsonRpcProvider, mainnetConnection } from '@mysten/sui.js'
// import { GAME_APE_ADDRESS } from '@/web3/contract.constant'
// import GameApeNFT from '@/web3/GameApeNFT'
import emitter from '@/common/js/emitter'
import { formatSUI } from '@/common/js/formatCurrency'
import social from '@/helpers/social'
import ConnectDialog from './ConnectDialog.vue'

export default {
  name: 'Nav',
  components: {
    ConnectDialog,
  },
  data() {
    return {
      active: false,

      eth: 0,
      sui: 0,
      nft: 0,

      social,
    }
  },
  methods: {
    onHover(active) {
      if (typeof active === 'undefined') {
        this.active = !this.active
      } else {
        this.active = active
      }
    },
    onFaq() {
      if (this.$route.path == '/') {
        const elem = document.querySelector('#faq')
        elem.scrollIntoView()
      } else {
        this.$router.push('/')
      }
    },
    onMint() {
      if (this.$route.path == '/') {
        const elem = document.querySelector('#nftMint')
        elem.scrollIntoView(false)

        emitter.emit('welcome-mint')
      } else {
        this.$router.push('/')
      }
    },
    async onConnect() {
      if (this.$root.walletAddr) return
      // await this.$root.connect()

      this.$refs.ConnectDialog.show()
    },

    async init() {
      try {
        // const contract = new GameApeNFT(GAME_APE_ADDRESS)

        // const ethBalance = await contract.getEthBalance()
        // this.eth = parseFloat(ethBalance).toFixed(6)

        // const nftBalance = await contract.balanceof()
        // this.nft = nftBalance

        const provider = new JsonRpcProvider(mainnetConnection)
        const coinBalance = await provider.getBalance({
          owner: this.$root.walletAddr,
          coinType: '0x2::sui::SUI',
        })
        this.sui = formatSUI(coinBalance.totalBalance)
      } catch (err) {
        console.error(err)
        this.eth = 0
        this.sui = 0
        this.nft = 0
      }
    },
  },
  async created() {
    await this.$root.connect()
    this.init()

    emitter.on('accountsChanged', () => {
      this.init()
    })
  },
}
</script>

<style lang="scss" scoped>
.comp-nav {
  position: relative;
  z-index: 10;
  padding: 1.5rem 1rem;
  .nav-main {
    margin: 0 auto;
    max-width: 51.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .logo-wrap {
    display: block;
    .logo {
      display: block;
      height: 3.9rem;
      @media (max-width: 768.89px) {
        height: 3.3rem;
      }
    }
  }
  .menu-wrap {
    position: relative;
    position: relative;
    width: 17rem;
    font-size: 0.6rem;
    @media (max-width: 768.89px) {
      width: 18.6rem;
    }
    &.active {
      .list {
        visibility: visible;
        transition: none;
        .item {
          opacity: 1 !important;
          transform: none !important;
        }
      }
    }
    .bg {
      display: block;
      width: 100%;
    }
    .info {
      position: absolute;
      top: 4%;
      left: 9%;
      right: 28%;
      display: flex;
      padding: 0 4.5% 0 4.2%;
      .item {
        position: relative;
        flex: 4;
        display: flex;
        align-items: center;
        &:hover {
          .tips {
            opacity: 1;
            top: -1rem;
          }
        }
      }
      .icon {
        display: block;
        width: 1.4rem;
      }
      .text {
        display: block;
        flex: 1;
        width: 50%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .tips {
        opacity: 0;
        position: absolute;
        top: -0.75rem;
        left: -0.8rem;
        padding: 0 0.2rem;
        line-height: 1rem;
        font-size: 0.6rem;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 0.2rem;
        transition: opacity 400ms, top 400ms;
      }
      .sui {
        .icon {
          margin: 0 0.2rem;
          width: 0.8rem;
          filter: brightness(100);
        }
      }
    }
    .account {
      position: absolute;
      top: 31%;
      left: 2%;
      right: 22%;
      padding: 1.3% 5% 1.1% 3%;
      line-height: 1.5;
      text-align: center;
      .text {
        font-family: 'Cansiparane-Bold';
        font-size: 0.8rem;
      }
    }
    .avatar {
      position: absolute;
      top: 0;
      right: 7%;
      width: 25%;
      padding-top: 25%;
      border-radius: 50%;
    }
    .social-item {
      position: absolute;
      padding: 1.2%;
      width: 3.9%;
      opacity: 0.9;
      &:hover {
        opacity: 1;
      }
      img {
        width: 100%;
      }
      &.social-twitter {
        top: 79%;
        left: 82%;
      }
      &.social-discord {
        top: 66%;
        left: 88.6%;
      }
      &.social-opensea {
        top: 42.5%;
        left: 93.3%;
      }
    }
    .list {
      visibility: hidden;
      position: absolute;
      top: 77%;
      right: 22%;
      padding-top: 1.3%;
      line-height: 1.5;
      font-family: 'Cansiparane-Bold';
      font-size: 0.7rem;
      transition: visibility 1ms 300ms;
      @media (max-width: 768.89px) {
        line-height: 2.2;
      }
      .item {
        display: block;
        margin-bottom: 3%;
        text-align: right;
        transition: opacity 300ms, transform 300ms;
        &:first-child {
          opacity: 0;
          transform: translateY(-30%) translateZ(0);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateY(-60%) translateZ(0);
        }
        &:nth-child(3) {
          opacity: 0;
          transform: translateY(-90%) translateZ(0);
        }
      }
      .text {
        display: inline-block;
        padding: 0 0.5rem 0 1.2rem;
        color: #66ffbd;
        background-color: #0a0d6a;
        clip-path: polygon(0 0, 0.8rem 100%, 100% 100%, 100% 0);
        cursor: pointer;
        &:hover {
          box-shadow: 0 0 0.45rem 0.05rem inset #2e4cff;
        }
      }
    }
  }
}
</style>
