<template>
  <section class="home-partners">
    <main class="section-main">
      <h1 class="section-heading">Partners Tree</h1>
      <div class="content-wrap">
        <img class="bg" src="@/assets/home/partners-bg.png" />
        <img class="partners partners-11" src="@/assets/home/partners-11.png" />
        <img class="partners partners-12" src="@/assets/home/partners-12.png" />
        <img class="partners partners-13" src="@/assets/home/partners-13.png" />
        <img class="partners partners-14" src="@/assets/home/partners-14.png" />
        <img class="partners partners-15" src="@/assets/home/partners-15.png" />
        <img class="partners partners-16" src="@/assets/home/partners-16.png" />
        <img class="partners partners-21" src="@/assets/home/partners-21.png" />
        <img class="partners partners-31" src="@/assets/home/partners-31.png" />
        <img class="partners partners-32" src="@/assets/home/partners-32.png" />
        <img class="partners partners-33" src="@/assets/home/partners-33.png" />
        <img class="partners partners-34" src="@/assets/home/partners-34.png" />
        <img class="partners partners-35" src="@/assets/home/partners-35.png" />
        <img class="partners partners-36" src="@/assets/home/partners-36.png" />
        <img class="partners partners-41" src="@/assets/home/partners-41.png" />
        <img class="partners partners-42" src="@/assets/home/partners-42.png" />
        <img class="partners partners-51" src="@/assets/home/partners-51.png" />
        <img class="partners partners-52" src="@/assets/home/partners-52.png" />
        <img class="partners partners-53" src="@/assets/home/partners-53.png" />
        <img class="partners partners-61" src="@/assets/home/partners-61.png" />
        <img class="partners partners-62" src="@/assets/home/partners-62.png" />
        <img class="partners partners-63" src="@/assets/home/partners-63.png" />
        <img class="partners partners-64" src="@/assets/home/partners-64.png" />
        <img class="partners partners-65" src="@/assets/home/partners-65.png" />
        <img class="partners partners-66" src="@/assets/home/partners-66.png" />
        <img class="partners partners-71" src="@/assets/home/partners-71.png" />
        <img class="partners partners-72" src="@/assets/home/partners-72.png" />
        <img class="partners partners-73" src="@/assets/home/partners-73.png" />
        <img class="partners partners-74" src="@/assets/home/partners-74.png" />
        <img class="partners partners-75" src="@/assets/home/partners-75.png" />
        <img class="partners partners-76" src="@/assets/home/partners-76.png" />
      </div>
    </main>
  </section>
</template>

<script>
export default {
  name: 'HomePartners',
}
</script>

<style lang="scss">
.home-partners {
  margin-top: 6rem;
  @media (max-width: 768.89px) {
    margin: 3rem 1.5rem;
  }
  .section-heading {
    transition: transform 500ms, opacity 500ms;
  }
  .content-wrap {
    position: relative;
    margin: 4rem 0 4rem;
    transition: transform 500ms, opacity 500ms;
    .bg {
      margin: 0 auto;
      display: block;
      width: 92.48%;
    }
  }
  .partners {
    position: absolute;
    transition: transform 200ms;
    &:hover {
      transform: scale(1.05);
    }
  }
  .partners-11 {
    top: -3.5%;
    left: 3.7%;
    width: 6.0546%;
  }
  .partners-12 {
    top: 9%;
    left: -0.4%;
    width: 14.16%;
  }
  .partners-13 {
    top: 24.5%;
    left: -0.5%;
    width: 14.16%;
  }
  .partners-14 {
    top: 55.8%;
    left: -1.2%;
    width: 15.8%;
  }
  .partners-15 {
    top: 69%;
    left: 0.15%;
    width: 12.89%;
  }
  .partners-16 {
    top: 85%;
    left: -0.1%;
    width: 13.183%;
  }
  .partners-21 {
    top: 41.7%;
    left: 16.2%;
    width: 6.0546%;
  }
  .partners-31 {
    top: -3.5%;
    left: 25.6%;
    width: 12.304%;
  }
  .partners-32 {
    top: 8.5%;
    left: 28.65%;
    width: 6.0546%;
  }
  .partners-33 {
    top: 42.7%;
    left: 28.65%;
    width: 6.0546%;
  }
  .partners-34 {
    top: 55%;
    left: 28.65%;
    width: 6.0546%;
  }
  .partners-35 {
    top: 69%;
    left: 25.7%;
    width: 11.621%;
  }
  .partners-36 {
    top: 84.5%;
    left: 22%;
    width: 19.433%;
  }
  .partners-41 {
    top: 42.7%;
    left: 41.3%;
    width: 11.23%;
  }
  .partners-42 {
    top: 54%;
    left: 41.3%;
    width: 11.328%;
  }
  .partners-51 {
    top: -3.5%;
    left: 58.25%;
    width: 6.0546%;
  }
  .partners-52 {
    top: 9%;
    left: 58.25%;
    width: 6.0546%;
  }
  .partners-53 {
    top: 67%;
    left: 58.25%;
    width: 6.0546%;
  }
  .partners-61 {
    top: -1.5%;
    left: 75.7%;
    width: 6.0546%;
  }
  .partners-62 {
    top: 16.9%;
    left: 70.4%;
    width: 6.0546%;
  }
  .partners-63 {
    top: 34.8%;
    left: 68.5%;
    width: 13.183%;
  }
  .partners-64 {
    top: 51.3%;
    left: 65.5%;
    width: 13.183%;
  }
  .partners-65 {
    top: 66.7%;
    left: 68.5%;
    width: 6.0546%;
  }
  .partners-66 {
    top: 85%;
    left: 65.1%;
    width: 13.183%;
  }
  .partners-71 {
    top: -1.2%;
    left: 89.7%;
    width: 12.597%;
  }
  .partners-72 {
    top: 17.36%;
    left: 82.6%;
    width: 19.628%;
  }
  .partners-73 {
    top: 35%;
    left: 88.6%;
    width: 12.988%;
  }
  .partners-74 {
    top: 50.4%;
    left: 94.9%;
    width: 6.0546%;
  }
  .partners-75 {
    top: 66.95%;
    left: 82.6%;
    width: 19.433%;
  }
  .partners-76 {
    top: 85%;
    left: 88.4%;
    width: 13.183%;
  }

  &.animation {
    .section-heading {
      opacity: 0;
    }
    .content-wrap {
      // transform: rotate(960deg) scale(0.1) translateZ(0);
      transform: translateY(80%) translateZ(0);
      opacity: 0;
    }
  }
}
</style>
