<template>
  <Dialog class="nfts-dialog4" :visible="visible" @hide="onHide">
    <main :class="['nfts-main', visible ? 'fadeIn' : 'fadeOut']" @click="onHide">
      <img class="img" src="@/assets/home/nfts-4-2.jpg" />
    </main>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },
    onHide() {
      this.hide()
    },
  },
}
</script>

<style lang="scss">
.nfts-dialog4 {
  background: transparent;
  .dialog-main {
    height: 90%;
    @media (max-width: 768.89px) {
      height: auto;
    }
  }
  .nfts-main {
    margin: 0 auto;
    box-sizing: border-box;
    width: auto;
    height: 100%;
    padding: 2.5rem 3rem;
    border: 0.15rem solid transparent;
    border-radius: 0.5rem;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(0, #000, #000), linear-gradient(0, #12d6df, #f70fff);
    box-shadow: 0 0 3rem 0.5rem #0119c1, 0 0 1.5rem #0119c1, 0 0 3rem 0.5rem inset #0119c1,
      0 0 1.5rem inset #0119c1;
    overflow: hidden;
    @media (max-width: 768.89px) {
      padding: 1rem;
      width: 90%;
      height: auto;
    }
    &.fadeIn {
      animation: 300ms linear fadeIn both;
    }
    &.fadeOut {
      animation: 300ms linear fadeOut both;
    }
  }
  .img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
