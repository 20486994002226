<template>
  <footer class="comp-footer">
    <main class="footer-main">
      <h1 class="footer-heading">SUBSCRIBE NOW!</h1>
      <section class="email-wrap">
        <div class="email">
          <img class="email-icon" src="@/assets/footer/email.png" />
          <input
            class="email-input"
            type="text"
            placeholder="Please enter your email"
            v-model="email"
          />
          <a class="email-btn" @click="onSubmit">Submit</a>
        </div>
        <div class="email-tips" v-if="emailTips">{{ emailTips }}</div>
      </section>
      <section class="info-wrap">
        <section class="block block-1">
          <div class="social">
            <a
              v-for="item in social"
              :key="item.name"
              class="social-item"
              target="_blank"
              :href="item.link"
            >
              <img :src="item.image" />
            </a>
          </div>
        </section>
        <section class="block block-2">
          <h2 class="heading">Terms & Conditions</h2>
          <div class="navigation">
            <div class="list">
              <a class="item" target="_blank" href="/#/terms">Terms & Conditions</a>
            </div>
          </div>
        </section>
        <section class="block block-3">
          <h2 class="heading">General Inquiry</h2>
          <div class="detail">
            <div class="copy-tips" v-if="copySupportTips">{{ copySupportTips }}</div>
            <input
              class="copy-input"
              id="emailSupport"
              data-clipboard-target="#emailSupport"
              readonly
              value="support@gameapefantasyclub.com"
            />
          </div>
        </section>
        <section class="block block-4">
          <h2 class="heading">Business Cooperation</h2>
          <div class="detail">
            <div class="copy-tips" v-if="copyBusinessTips">{{ copyBusinessTips }}</div>
            <input
              class="copy-input"
              id="emailBusiness"
              data-clipboard-target="#emailBusiness"
              readonly
              value="business@gameapefantasyclub.com"
            />
          </div>
        </section>
      </section>
      <section class="copyright-wrap">
        <img class="logo" src="@/assets/footer/logo.png" />
        <a class="suigame" href="https://www.suigame.io/">
          <img class="suigame-logo" src="@/assets/footer/suigame-logo.png" />
          <i class="suigame-icon"></i>
        </a>
        <div class="copyright">Copyright © 2022 Game Ape Fantasy Club All Right Reserved</div>
      </section>
    </main>
  </footer>
</template>

<script>
import ClipboardJS from 'clipboard'
import social from '@/helpers/social'

export default {
  name: 'Footer',
  components: {},
  data() {
    return {
      social,

      email: '',
      emailTips: '',

      copySupportTips: '',
      copyBusinessTips: '',
    }
  },
  methods: {
    onSubmit() {
      const email = this.email.trim()
      if (!email) return

      const url = '/subcribe/email'
      const params = new URLSearchParams()
      params.append('email', email)

      this.$axios.post(url, params).then((res) => {
        if (res.code == 200) {
          this._showEmialTips('Success')
          return
        }

        this._showEmialTips(res.msg || 'Fail')
        throw new Error(res.msg)
      })
    },
    _showEmialTips(text) {
      this.emailTips = text
      clearTimeout(this._emailTimeout)
      this._emailTimeout = setTimeout(() => {
        this.emailTips = ''
      }, 4000)
    },

    _showCopySupportTips(text) {
      this.copySupportTips = text
      clearTimeout(this._copySupportTimeout)
      this._copySupportTimeout = setTimeout(() => {
        this.copySupportTips = ''
      }, 4000)
    },
    _showCopyBusinessTips(text) {
      this.copyBusinessTips = text
      clearTimeout(this._copyBusinessTimeout)
      this._copyBusinessTimeout = setTimeout(() => {
        this.copyBusinessTips = ''
      }, 4000)
    },
  },
  mounted() {
    new ClipboardJS('#emailSupport').on('success', () => {
      this._showCopySupportTips('Copied')
    })
    new ClipboardJS('#emailBusiness').on('success', () => {
      this._showCopyBusinessTips('Copied')
    })
  },
}
</script>

<style lang="scss">
.comp-footer {
  background-color: #001cff;
  background-image: linear-gradient(#001dcf, #001cff);
  @media (max-width: 768.89px) {
    padding: 0 1rem;
  }
  .footer-main {
    margin: 0 auto;
    max-width: 51.2rem;
    padding: 2rem 0;
    font-family: 'Gotham-Book';
  }
  .footer-heading {
    padding: 0.5rem 0;
    font-family: 'Cansiparane-Bold';
    font-size: 1.1rem;
    font-weight: bold;
    text-align: center;
  }
  .email-wrap {
    position: relative;
    margin: 0.5rem 0;
    font-family: 'Gotham-Medium';
    font-size: 0.7rem;
    .email {
      display: flex;
      align-items: center;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 0 1.5rem;
      width: 21rem;
      height: 3rem;
      border: 0.05rem solid #36edff;
      border-radius: 0.3rem;
      @media (max-width: 768.89px) {
        width: 25rem;
      }
    }
    .email-icon {
      height: 1.1rem;
    }
    .email-input {
      margin: 0 0.5rem;
      flex: 1;
      width: 0;
      font-size: 1.1em;
      color: #36edff;
      border: 0 none;
      background: transparent;
      outline: none;
      cursor: pointer;
    }
    .email-btn {
      display: block;
      padding: 0 0.8rem;
      line-height: 1.5rem;
      color: #0015b4;
      background-color: #36edff;
      border-radius: 0.3rem;
    }
    .email-tips {
      position: absolute;
      left: 50%;
      top: 120%;
      transform: translateX(-50%);
      display: inline-block;
      padding: 0 0.5rem;
      line-height: 1rem;
      font-size: 0.7rem;
      color: #0016b9;
      background-color: #fff;
      border-radius: 1rem;
      animation: tipsAnimation 2s linear both;
    }
  }
  .info-wrap {
    display: flex;
    justify-content: space-evenly;
    margin: 3.6rem 0 2rem;
    font-size: 0.6rem;
    color: #4ef5ec;
    text-align: left;
    @media (max-width: 768.89px) {
      display: block;
      margin: 2.5rem 0 1rem;
    }
    .block {
      position: relative;
      box-sizing: border-box;
      width: 10rem;
      @media (max-width: 768.89px) {
        margin-bottom: 2rem;
        text-align: center;
        width: auto !important;
      }
      &.block-1 {
        width: 8rem;
      }
      &.block-2 {
        padding-left: 2.5rem;
        border-left: 0.05rem solid rgba(54, 237, 255, 0.5);
        @media (max-width: 768.89px) {
          padding-left: 0;
          border-left: 0;
        }
      }
      &.block-3,
      &.block-4 {
        width: 11rem;
      }
    }
    .heading {
      margin-bottom: 0.5rem;
      font-family: 'Cansiparane-Bold';
      font-size: 0.6rem;
      font-weight: bold;
      color: #36edff;
    }
    .social-item {
      display: inline-block;
      width: 1.9rem;
      height: 1.9rem;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      @media (max-width: 768.89px) {
        width: 2.6rem;
        height: 2.6rem;
      }
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
      }
    }
    .navigation {
      display: flex;
      .list {
        flex: 1;
        line-height: 1.6;
      }
      .item {
        display: block;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .detail {
      position: relative;
      line-height: 1.6;
    }
    .copy-input {
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
      line-height: inherit;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
      background: transparent;
      border: 0 none;
      outline: none;
      cursor: pointer;

      @media (max-width: 768.89px) {
        text-align: center;
      }
    }
    .copy-tips {
      position: absolute;
      left: 70%;
      bottom: 145%;
      transform: translateX(-50%);
      display: inline-block;
      padding: 0 0.5rem;
      line-height: 1rem;
      font-size: 0.7rem;
      color: #0016b9;
      background-color: #fff;
      border-radius: 1rem;
      animation: tipsAnimation 2s linear both;
    }
  }
  .copyright-wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-top: 0.05rem solid rgba(54, 237, 255, 0.5);
    padding-top: 1rem;
    font-family: 'Gotham-Medium';
    font-size: 1rem;
    @media (max-width: 768.89px) {
      justify-content: space-between;
    }
    .logo {
      height: 2em;
      @media (max-width: 768.89px) {
        margin-left: 0.75rem;
      }
    }
    .suigame {
      margin: 0 10%;
      display: block;
      padding: 0.5em 0.75em;
      @media (max-width: 768.89px) {
        font-size: 1.15em;
        margin: 0 0 0 10%;
      }
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 0.3em;
        .suigame-icon {
          background-image: url('@/assets/footer/suigame-icon2.png');
        }
      }
      .suigame-logo {
        margin-right: 0.7em;
        height: 1.5em;
        vertical-align: middle;
      }
      .suigame-icon {
        display: inline-block;
        width: 1.25em;
        height: 1.25em;
        background: url('@/assets/footer/suigame-icon1.png') center center / 80% auto no-repeat;
        vertical-align: middle;
      }
    }
    .copyright {
      flex: 1;
      font-size: 0.65em;
      text-align: right;
      @media (max-width: 768.89px) {
        flex: none;
        margin-top: 1em;
        width: 100%;
        font-size: 1em;
        text-align: center;
      }
    }
  }
  @keyframes tipsAnimation {
    0% {
      opacity: 0;
      transform: translateX(-50%) translateY(50%) translateZ(0);
    }
    18% {
      opacity: 1;
      transform: translateX(-50%) translateZ(0);
    }
    82% {
      opacity: 1;
      transform: translateX(-50%) translateZ(0);
    }
    100% {
      opacity: 0;
      transform: translateX(-50%) translateY(50%) translateZ(0);
    }
  }
}
</style>
