import ContractBase from './ContractBase'
import abi from './GameApeNFT.abi'

export default class GameApeNFT extends ContractBase {
  constructor(contractAddress) {
    super(contractAddress, abi)
  }

  async getEthBalance() {
    const weiBalance = await this._web3.eth.getBalance(this._address)
    const ethBalance = this._web3.utils.fromWei(weiBalance)
    return ethBalance
  }

  async totalSupply() {
    const num = await this._contract.methods.totalSupply().call()
    return num
  }
  async tokenByIndex(ith) {
    const tokenId = await this._contract.methods.tokenByIndex(ith).call()
    return tokenId
  }
  //查询账户总共有多少nft
  async balanceof(userWalletAddress) {
    userWalletAddress = userWalletAddress || this._address

    const balance = await this._contract.methods.balanceOf(userWalletAddress).call()
    return balance
  }

  async tokenURI(tokenId) {
    let result = {}
    try {
      const URI = await this._contract.methods.tokenURI(tokenId).call()
      result = JSON.parse(URI)
    } catch (err) {
      console.warn(err)
    }
    return result
  }

  //查询账户的所有tokenID
  async tokenOfOwner() {
    const tokenList = await this._contract.methods.tokenOfOwner(this._address).call()
    return tokenList || []
  }

  //查询账户的每一个tokenID
  async tokenOfOwnerByIndex(ith) {
    const tokenId = await this._contract.methods.tokenOfOwnerByIndex(this._address, ith).call()
    return tokenId
  }
  async bExistsID(tokenId) {
    const bExit = await this._contract.methods.bExistsID(tokenId).call()
    return bExit
  }

  //
  async isApprovedForAll(owner, operator) {
    const isOk = await this._contract.methods.isApprovedForAll(owner, operator).call()
    return isOk
  }

  async getParameters(userWalletAddress) {
    userWalletAddress = userWalletAddress || this._address
    const numArr = await this._contract.methods.getParameters(userWalletAddress).call()
    const result = {}
    result.totalSupply = numArr[0]
    result.balances = numArr[1]
    result.mintTotalWhite = numArr[2]
    result.mintTotalPublic = numArr[3]

    result.mintTotalFund = numArr[4]
    result.benefitsInvitation = numArr[5]
    result.InviterNum = numArr[6]
    result.balanceETH = this._web3.utils.fromWei(numArr[7], 'ether')
    return result
  }
  async getMintWhitePriceNum(numberOfTokens) {
    const price = await this._contract.methods.getMintWhitePriceNum(numberOfTokens).call()
    const etherBalance = this._web3.utils.fromWei(price, 'ether')
    return etherBalance
  }
  async mintPricePublic() {
    const price = await this._contract.methods.mintPricePublic().call()
    const etherBalance = this._web3.utils.fromWei(price, 'ether')
    return etherBalance
  }
  async transferFrom(recipient, tokenId) {
    return this.sendTransaction('transferFrom', [this._address, recipient, tokenId])
  }

  async setApprovalForAll(recipient, bTrue) {
    return this.sendTransaction('setApprovalForAll', [recipient, bTrue])
  }

  async safeTransferFrom(recipient, tokenId) {
    return this.sendTransaction('safeTransferFrom', [this._address, recipient, tokenId])
  }
  async mintWhiteMulti(numberOfTokens) {
    var amount = await this.getMintWhitePriceNum(numberOfTokens)
    const weiValue = this._web3.utils.toWei(String(amount), 'ether')
    const bnValue = this._web3.utils.toBN(weiValue)
    return this.sendTransaction('mintWhiteMulti', [numberOfTokens], {
      value: bnValue,
    })
  }
  async mintWhite_AddInviter(inviterAddr, numberOfTokens) {
    var amount = await this.getMintWhitePriceNum(numberOfTokens)
    const weiValue = this._web3.utils.toWei(String(amount), 'ether')
    const bnValue = this._web3.utils.toBN(weiValue)
    return this.sendTransaction('mintWhite_AddInviter', [inviterAddr, numberOfTokens], {
      value: bnValue,
    })
  }
  async mintPublicMulti(numberOfTokens) {
    var price = await this.mintPricePublic()
    var amount = numberOfTokens * price
    const weiValue = this._web3.utils.toWei(String(amount), 'ether')
    const bnValue = this._web3.utils.toBN(weiValue)
    return this.sendTransaction('mintPublicMulti', [numberOfTokens], {
      value: bnValue,
    })
  }
  async mintPublic_AddInviter(inviterAddr, numberOfTokens) {
    var price = await this.mintPricePublic()
    var amount = numberOfTokens * price
    const weiValue = this._web3.utils.toWei(String(amount), 'ether')
    const bnValue = this._web3.utils.toBN(weiValue)
    return this.sendTransaction('mintPublic_AddInviter', [inviterAddr, numberOfTokens], {
      value: bnValue,
    })
  }
}
