<template>
  <div class="activity-slider">
    <div class="slider-wrap">
      <div class="slider-left" @click="onLeft"></div>
      <div
        class="slider-main"
        @touchstart.prevent.stop="onTouchStart"
        @touchend.prevent.stop="onTouchEnd"
      >
        <div class="slider-item" v-for="item in list" :key="item.img" :style="item.style">
          <div class="inner">
            <video class="video" :src="item.video" :poster="item.img" @click="onVideo"></video>
          </div>
        </div>
      </div>
      <div class="slider-right" @click="onRight"></div>
    </div>
    <!-- <div class="slider-dots">
      <div
        v-for="(item, index) in list"
        :key="item.img"
        :class="['dot', current === index ? 'active' : '']"
      ></div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      list: [
        {
          img: require('@/assets/home/activity-slider-1.png'),
          video: 'https://static.forthbox.io/video/GAFC/NFT_show.mp4',
          style: '',
        },
        {
          img: require('@/assets/home/activity-slider-2.png'),
          video: 'https://static.forthbox.io/video/GAFC/01.mp4',
          style: '',
        },
        {
          img: require('@/assets/home/activity-slider-3.png'),
          video: 'https://static.forthbox.io/video/GAFC/Taroverse.mp4',
          style: '',
        },
      ],

      current: 0,
    }
  },
  methods: {
    onTouchStart(evt) {
      const touches = evt.changedTouches
      const touch = touches[0]
      this._start = {
        pageX: touch.pageX,
        pageY: touch.pageY,
      }
    },
    onTouchEnd(evt) {
      const touches = evt.changedTouches
      const touch = touches[0]
      const end = {
        pageX: touch.pageX,
        pageY: touch.pageY,
      }
      const start = this._start
      const vDistance = Math.abs(end.pageY - start.pageY)
      const hDistance = end.pageX - start.pageX
      if (vDistance <= 20 && hDistance >= 40) {
        this.onRight()
      } else if (vDistance <= 20 && hDistance <= -40) {
        this.onLeft()
      }
    },

    onVideo(evt) {
      const video = evt.target
      if (!video.controls) {
        video.controls = true
        setTimeout(() => {
          video.play()
        }, 0)
      }
    },

    onLeft() {
      this.toLeft()
    },
    onRight() {
      this.toRight()
    },

    slideTo(index, direction) {
      if (this._sliding || (direction && this.list.length <= 3)) return

      const current = this.current
      this.current = index

      if (direction) {
        this._calc(current, direction)
        setTimeout(() => {
          this._calc(current, direction, 1)
          this._sliding = true
          setTimeout(() => {
            this._sliding = false
          }, 800)
        }, 10)
      } else {
        this._calc(index, direction)
      }
    },
    _calc(current, direction = 0, step = 0) {
      const list = this.list || []
      const length = list.length

      let diffs = [0, 1, 2]
      if (direction > 0) {
        // 向左
        diffs = [0, 1, 2, 3]
      } else if (direction < 0) {
        // 向右
        diffs = [-1, 0, 1, 2]
      }
      const indexs = diffs.map((diff) => (current + diff + length) % length)

      list.forEach((item, idx) => {
        const order = indexs.indexOf(idx)
        if (order > -1) {
          const diff = diffs[order]
          item.style = [
            `z-index: ${10 - direction * diff}`,
            `left: ${33.6 * (diff - direction * step)}%`,
            step > 0 ? 'transition: left 800ms' : '',
          ].join('; ')
        } else {
          item.style = ['display: none'].join('; ')
        }
      })
    },
    toRight() {
      const length = this.list.length
      const current = this.current
      this.slideTo((current + length - 1) % length, -1)
    },
    toLeft() {
      const length = this.list.length
      const current = this.current
      this.slideTo((current + 1) % length, 1)
    },
  },
  mounted() {
    this.slideTo(0)
  },
}
</script>

<style lang="scss" scoped>
.activity-slider {
  margin: 1.5rem -2% 0.5rem;
  .slider-wrap {
    display: flex;
  }
  .slider-left {
    width: 2%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    &::before {
      content: '';
      display: inline-block;
      border-top: solid transparent 0.5rem;
      border-bottom: solid transparent 0.5rem;
      border-right: solid #fff 0.6rem;
    }
  }
  .slider-right {
    width: 2%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    &::before {
      content: '';
      display: inline-block;
      border-top: solid transparent 0.5rem;
      border-bottom: solid transparent 0.5rem;
      border-left: solid #fff 0.6rem;
    }
  }
  .slider-main {
    position: relative;
    width: 96%;
    padding-top: 20.2%;
    flex: 1;
    overflow: hidden;
  }
  .slider-item {
    position: absolute;
    top: 0;
    width: 32.8%;
    padding-top: 20.85%;
    transform: translateZ(0);
    cursor: pointer;
    .inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    .video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .slider-dots {
    line-height: 2rem;
    text-align: center;
    .dot {
      display: inline-block;
      margin: 0 0.2rem;
      width: 0.3rem;
      height: 0.3rem;
      background-color: #fff;
      border-radius: 50%;
      &.active {
        background-color: #1c0efc;
      }
    }
  }
}
</style>
