<template>
  <section class="home-activity animation">
    <main class="section-main">
      <h1 class="section-heading">Our Activity</h1>
      <div class="content-wrap">
        <div class="activity-desc">
          <a class="desc-1" @click="onActivity">
            <img class="bg" src="@/assets/home/activity-1.png" />
          </a>
          <a class="desc-2" @click="onActivity">
            <img class="bg" src="@/assets/home/activity-2.png" />
          </a>
          <a class="desc-3" @click="onActivity">
            <img class="bg" src="@/assets/home/activity-3.png" />
          </a>
        </div>
        <ActivitySlider></ActivitySlider>
      </div>
    </main>
  </section>
</template>

<script>
import ActivitySlider from './ActivitySlider.vue'

export default {
  name: 'HomeActivity',
  components: { ActivitySlider },
  data() {
    return {}
  },
  methods: {
    onActivity() {
      this.$modal.toast({
        content: 'Please stay tuned!',
      })
    },
  },
}
</script>

<style lang="scss">
.home-activity {
  margin-top: 4rem;
  font-family: 'Cansiparane-Bold';
  @media (max-width: 768.89px) {
    margin: 2rem 1rem;
  }
  .section-heading {
    transition: transform 500ms, opacity 500ms;
  }
  .content-wrap {
    margin-top: 2.5rem;
    transition: transform 500ms, opacity 500ms;
    .bg {
      display: block;
      width: 100%;
    }
  }
  .activity-desc {
    display: flex;
    justify-content: space-between;
    .desc-1 {
      display: block;
      width: 49.609%;
      cursor: pointer;
    }
    .desc-2 {
      display: block;
      width: 24.121%;
      cursor: pointer;
    }
    .desc-3 {
      display: block;
      width: 23.73%;
      cursor: pointer;
    }
  }

  &.animation {
    .section-heading {
      opacity: 0;
    }
    .content-wrap {
      transform: translateY(80%) translateZ(0);
      opacity: 0;
    }
  }
}
</style>
