<template>
  <Dialog class="connect-dialog" :visible="visible">
    <main class="connect-main">
      <header class="header">
        <h1 class="title">Connect Wallet</h1>
        <a class="close" @click="onHide"></a>
      </header>
      <section class="content">
        <ul class="list">
          <li
            class="item"
            v-for="wallet in supportedWallet"
            :key="wallet.name"
            @click="onConnect(wallet.name)"
          >
            <img class="icon" :src="wallet.icon" />
            <span class="text">{{ wallet.label }}</span>
          </li>
        </ul>
      </section>
    </main>
  </Dialog>
</template>

<script>
import Dialog from './Dialog.vue'
import { WALLET } from '@/web3/wallet-sui'

export default {
  components: {
    Dialog,
  },
  data() {
    return {
      visible: false,

      supportedWallet: WALLET,
    }
  },
  methods: {
    show() {
      this.visible = true
    },
    hide() {
      this.visible = false
    },

    onHide() {
      this.hide()
    },
    async onConnect(wallet) {
      await this.$root.connect(wallet)
      this.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.connect-dialog {
  .connect-main {
    box-sizing: border-box;
    width: 26rem;
    font-size: 1rem;
    text-align: left;
    background-color: #fff;
    border-radius: 0.8em;
    @media (max-width: 768.89px) {
      width: 15rem;
      font-size: 0.8rem;
    }
    &::before,
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
  .header {
    display: flex;
    align-items: center;
    line-height: 3.5em;
    .title {
      flex: 1;
      margin: 0 0 0 2.5em;
      font-size: inherit;
      font-weight: 500;
      color: #222;
      text-align: center;
    }
    .close {
      display: block;
      margin-right: 0.5em;
      width: 2em;
      height: 2em;
      background: url('~@/assets/common/close.png') center center / 0.8em no-repeat;
      cursor: pointer;
      &:hover {
        filter: brightness(0.8);
      }
    }
  }
  .content {
    padding: 0 2em 2em;
    font-size: 0.8em;
    color: #6b6b6e;
    .list {
      margin: 0 0 0.8em;
      border: 0.05rem solid #e5e8eb;
      border-radius: 0.5em;
      overflow: hidden;
    }
    .item {
      display: flex;
      align-items: center;
      padding: 0 1.2em;
      line-height: 3.2em;
      text-align: left;
      font-weight: 500;
      border-bottom: 0.05rem solid rgba(#e5e8eb, 0.5);
      cursor: pointer;
      &:last-child {
        border-bottom: 0 none;
      }
      &:hover {
        background-color: #f6f6f6;
      }
      .icon {
        margin-right: 0.8em;
        width: 1.8em;
        height: 1.8em;
      }
      .text {
        flex: 1;
      }
      &::after {
        content: '';
        display: inline-block;
        width: 1em;
        height: 1em;
        background: url('~@/assets/common/icon-arrow.png') center center / contain no-repeat;
      }
    }
  }
}
</style>
