<template>
  <div class="ga-dialog" v-if="iVisible" @click.self="onHide">
    <div
      :class="['dialog-bg', animateIn ? 'fadeIn' : 'fadeOut']"
      :style="bgVisible ? '' : 'visibility: hidden;'"
      @click.self="onHide"
      @touchmove.prevent.stop
    ></div>
    <div class="dialog-main">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    visible: {
      type: Boolean,
    },
    bgVisible: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      animateIn: false,
      iVisible: false,
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.animateIn = true
        this.iVisible = true
      } else {
        this.animateIn = false
        setTimeout(() => {
          this.iVisible = false
        }, 400)
      }
    },
  },
  methods: {
    onHide() {
      this.$emit('hide')
    },
  },
}
</script>

<style lang="scss">
.ga-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 0;
  // background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  &::before {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
  }
  .dialog-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transform: translateZ(0);
    &.fadeIn {
      animation: 300ms linear fadeIn both;
    }
    &.fadeOut {
      animation: 300ms linear fadeOut both;
    }
  }
  .dialog-main {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    font-size: 0.7rem;
    &::before,
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
}
</style>
