<template>
  <section class="home-welcome">
    <main class="section-main">
      <div class="welcome-1">
        <img class="bg" src="@/assets/home/welcome-1.png" />
        <div class="tips" ref="tipsWrap">
          <span class="text" ref="tipsText">{{ ledText }}</span>
        </div>
        <div class="nft-screen">
          <div v-if="status == ''" class="screen-default">
            <img class="img" src="@/assets/home/welcome-default.png" />
            <div class="info">
              <div>
                <p class="label">Birth:</p>
                <p class="value">2022/06/23</p>
              </div>
              <div>
                <p class="label">Gender:</p>
                <p class="value">Male</p>
              </div>
              <div>
                <p class="label">Charactristics:</p>
                <p class="value">Transparent body</p>
                <p class="value">Playful + Joyful</p>
              </div>
            </div>
            <a class="anchor anchor-1">
              <i class="icon"></i>
              <span class="text">Camera</span>
            </a>
            <a class="anchor anchor-2">
              <i class="icon"></i>
              <span class="line"></span>
              <span class="text">Directional keys</span>
            </a>
            <a class="anchor anchor-3">
              <i class="icon"></i>
              <span class="line"></span>
              <span class="text">Start Button</span>
            </a>
          </div>
          <div v-if="status == 'progress'" class="screen-progress">
            <div>
              <Loading class="loading"></Loading>
              <div class="text">Minting...</div>
            </div>
          </div>
          <div v-if="status == 'success'" class="screen-success">
            <img class="bg" src="@/assets/home/welcome-success.gif" />
          </div>
          <div v-if="status == 'fail'" class="screen-fail">
            <img class="bg" src="@/assets/home/welcome-fail.gif" />
          </div>
          <div v-if="status == 'explain'" class="screen-text">
            <div class="inner">
              <section :class="['page', explainPage == 1 ? '' : 'hide']">
                <h2 class="title">Game Ape Fantasy Club</h2>
                <p>
                  Game Ape Fantasy Club is an open platform dedicated to creating a Metaverse game
                  trend and lifestyle.
                </p>
                <p>
                  Game Ape Fantasy Club is created by the Game Ape DAO. The DAO is initiated by a
                  group of BAYC holders, GameFi projects, game guilds, infrastructure builders,
                  investment institutions, and famous crypto influencers.
                </p>
              </section>
              <section :class="['page', explainPage == 2 ? '' : 'hide']">
                <h2 class="title m-v-hide">Game Ape Fantasy Club</h2>
                <p>
                  Through the unique game culture of the club, we summon fans and institutions of
                  the Web3 game industry to build a Metaverse game community. We will join and
                  prosper the game community of the bored ape ecosystem, activate the
                  blockchain-based economy, and eventually, develop the Metaverse game open
                  ecosystem together.
                </p>
              </section>
              <section :class="['page', explainPage == 3 ? '' : 'hide']">
                <h2 class="title">About Game Ape</h2>
                <p>
                  Based on BAYC #4949 (licensed) and well-known game characters, all the Game Ape
                  NFTs are tailored-made (instead of generated by AI algorithm ). A total of 1024
                  unique Game Ape NFTs are living on the Ethereum blockchain, representing the
                  doppelgänger of a variety of classic game characters in different eras across the
                  globe.
                </p>
              </section>
              <section :class="['page', explainPage == 4 ? '' : 'hide']">
                <h2 class="title m-v-hide">About Game Ape</h2>
                <p>
                  <i>
                    “This is an arcade, warrior. Game Ape NFT is the token for the brave, insert it,
                    and enter the Metaverse. From now on, your story begins.”
                  </i>
                </p>
              </section>
            </div>
            <div class="page-btns">
              <a v-if="explainPage > 1" class="page-btn icon-left" @click="onExplainPrev">LAST</a>
              <a v-if="explainPage < 4" class="page-btn icon-right" @click="onExplainNext">NEXT</a>
            </div>
            <a class="btn-back page-btn icon-left" @click="onExplainBack">BACK</a>
          </div>
          <div v-if="status == 'white'" class="screen-text">
            <div class="inner">
              <p></p>
            </div>
          </div>
        </div>
        <div class="nft-operation">
          <div class="nft-last">
            <div class="num">{{ lastNft }}</div>
            <div class="text">
              NFT<br />
              LEFT
            </div>
          </div>
          <div id="nftMint" class="nft-mint" @click="onMint">
            <img v-if="focusVisible" class="focus" src="@/assets/home/welcome-focus.gif" />
            <div class="icon"></div>
            <div class="text">
              <div>CLICK TO</div>
              <div class="big">MINT</div>
              <div>YOUR NFT</div>
            </div>
          </div>
        </div>
      </div>
      <div class="welcome-2" @click="onExplain">
        <!-- <div class="text">ABOUT US</div> -->
      </div>
      <div class="welcome-3" @click="onWhite">
        <Label class="bg normal"></Label>
        <img class="bg active" src="@/assets/home/welcome-3a.png" />
      </div>
      <div :class="['welcome-4', nftId ? '' : 'hide']">
        <img class="bg" src="@/assets/home/welcome-4.png" />
        <img class="cover" src="@/assets/home/welcome-4c.png" />
        <div class="nft-id">
          <div><small>#</small>{{ nftId }}</div>
          <img class="barcode" id="nftIdBarcode" />
        </div>
      </div>
      <div
        :class="['welcome-5-1', pushBtn == 1 ? 'active' : '']"
        @mousedown="onPush(1)"
        @mouseup="onPush()"
      ></div>
      <div
        :class="['welcome-5-2', pushBtn == 2 ? 'active' : '']"
        @mousedown="onPush(2)"
        @mouseup="onPush()"
      ></div>
      <div class="welcome-6-1"></div>
      <div class="welcome-6-2"></div>
      <div class="welcome-6-3"></div>
      <div class="welcome-6-4"></div>
      <div class="welcome-7"></div>
    </main>
  </section>
</template>

<script>
import JsBarcode from 'jsbarcode'
import { GAME_APE_ADDRESS } from '@/web3/contract.constant'
import GameApeNFT from '@/web3/GameApeNFT'
import emitter from '@/common/js/emitter'
import Loading from './Loading'
import Label from './Label'

export default {
  name: 'HomeWelcome',
  components: {
    Loading,
    Label,
  },
  props: {},
  data() {
    return {
      ledText: 'Welcome to the Game Ape World!',

      pushBtn: '',

      explainPage: 1,

      lastNft: 1024,
      nftId: '',

      focusVisible: false,

      status: '',
    }
  },
  methods: {
    onExplain() {
      clearTimeout(this._mintTimeout)
      this.focusVisible = false
      this.status = this.status == 'explain' ? '' : 'explain'
      if (this.status) {
        this.explainPage = 1
      }
    },
    onExplainBack() {
      this.focusVisible = false
      this.status = ''
    },
    onExplainPrev() {
      this.explainPage = this.explainPage <= 1 ? 1 : this.explainPage - 1
    },
    onExplainNext() {
      this.explainPage = this.explainPage >= 4 ? 4 : this.explainPage + 1
    },

    onWhite() {
      clearTimeout(this._mintTimeout)
      this.focusVisible = false
      this.status = this.status == 'white' ? '' : 'white'
    },

    onPush(id) {
      this.pushBtn = this.pushBtn == id ? '' : id
    },

    async onMint() {
      this.$modal.toast({
        content: 'Please stay tuned!',
      })
      return

      // this.nftId = ''

      // this.focusVisible = false
      // this.status = 'progress'

      // try {
      //   await this._contract.mintWhiteMulti(1)

      //   const tokenIds = await this._contract.tokenOfOwner()
      //   this.nftId = tokenIds[tokenIds.length - 1]
      //   if (!this.nftId) throw new Error('has not nft!')

      //   this.status = 'success'
      //   this._genBarcode(this.nftId)
      // } catch (err) {
      //   console.log(err)
      //   this.status = 'fail'
      // }
    },
    _genBarcode(nftId) {
      if (!nftId) return

      JsBarcode('#nftIdBarcode', nftId, {
        displayValue: false,
        height: 46,
        margin: 0,
        background: 'transparent',
      })
    },

    async init() {
      try {
        const contract = new GameApeNFT(GAME_APE_ADDRESS)
        this._contract = contract

        // TODO 正式代码先屏蔽
        // const mintCount = await contract.totalSupply()
        // this.lastNft = 1024 - mintCount
        // TODO 临时代码，展示结果为1024
        this.lastNft = 1024
      } catch (err) {
        this.lastNft = 1024
      }
    },
  },
  mounted() {
    this._genBarcode(this.nftId)

    emitter.on('welcome-mint', () => {
      this.focusVisible = true
    })

    document.addEventListener('click', () => {
      if (this.status == 'success' || this.status == 'fail') {
        this.status = ''
      }
    })
  },
  async created() {
    await this.$root.connect()
    this.init()

    emitter.on('accountsChanged', () => {
      this.init()
    })
  },
}
</script>

<style lang="scss" scoped>
.home-welcome {
  font-family: 'Krungthep';
  @media (max-width: 768.89px) {
    margin: 0 1rem;
  }
  .section-main {
    position: relative;
    .bg {
      display: block;
      width: 100%;
    }
  }
  .welcome-1 {
    position: relative;
    display: block;
    margin: 0 auto;
    padding-top: 65.4296875%;
    width: 86.5234%;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .tips {
      position: absolute;
      top: 5.1%;
      left: 14%;
      width: 72%;
      height: 9.5%;
      font-family: 'Led';
      font-size: 1.3rem;
      color: #fff;
      text-align: center;
      display: flex;
      align-items: center;
      white-space: nowrap;
      overflow: hidden;
      @media (max-width: 768.89px) {
        font-size: 0.85rem;
      }
      .text {
        width: 100%;
        animation: twinkle-tips 6s linear infinite both;
        // animation: led 10s linear infinite both;
      }
    }
    @keyframes twinkle-tips {
      0% {
        opacity: 1;
        text-shadow: 0 0 0.4rem #ed25ff, 0 0 0.8rem #ed25ff, 0 0 1.2rem #ed25ff, 0 0 1.6rem #ed25ff;
      }
      8.33% {
        opacity: 0;
      }
      16.66% {
        opacity: 1;
        text-shadow: 0 0 0.4rem #252aff, 0 0 0.8rem #252aff, 0 0 1.2rem #252aff, 0 0 1.6rem #252aff;
      }
      24.99% {
        opacity: 0;
      }
      33.33% {
        opacity: 1;
        text-shadow: 0 0 0.4rem #25e8ff, 0 0 0.8rem #25e8ff, 0 0 1.2rem #25e8ff, 0 0 1.6rem #25e8ff;
      }
      41.66% {
        opacity: 0;
      }
      49.99% {
        opacity: 1;
        text-shadow: 0 0 0.4rem #25f7ff, 0 0 0.8rem #25f7ff, 0 0 1.2rem #25f7ff, 0 0 1.6rem #25f7ff;
      }
      58.33% {
        opacity: 0;
      }
      66.66% {
        opacity: 1;
        text-shadow: 0 0 0.4rem #ffa025, 0 0 0.8rem #ffa025, 0 0 1.2rem #ffa025, 0 0 1.6rem #ffa025;
      }
      74.99% {
        opacity: 0;
      }
      83.33% {
        opacity: 1;
        text-shadow: 0 0 0.4rem #ff25d9, 0 0 0.8rem #ff25d9, 0 0 1.2rem #ff25d9, 0 0 1.6rem #ff25d9;
      }
      91.66% {
        opacity: 0;
      }
      100% {
        opacity: 1;
        text-shadow: 0 0 0.4rem #ed25ff, 0 0 0.8rem #ed25ff, 0 0 1.2rem #ed25ff, 0 0 1.6rem #ed25ff;
      }
    }
  }
  .nft-screen {
    position: absolute;
    top: 22.2%;
    left: 21.1%;
    width: 57.8%;
    height: 52.1%;

    .screen-default {
      box-sizing: border-box;
      padding: 0.3rem;
      height: 100%;
      .img {
        display: block;
        margin: 0 0 0 16%;
        max-width: 100%;
        max-height: 100%;
      }
      .info {
        position: absolute;
        top: 20%;
        left: 5%;
        width: 40%;
        font-size: 0.6rem;
        @media (max-width: 768.89px) {
          font-size: 0.4rem;
        }
        .label {
          margin: 0.3rem 0 0 0.4rem;
          line-height: 1.5;
        }
        .value {
          margin-bottom: 0.2rem;
          display: inline-block;
          padding: 0 0.4rem;
          line-height: 0.9rem;
          font-size: 0.65rem;
          color: #36edff;
          border: 0.05rem solid #36edff;
          border-radius: 1rem;
          @media (max-width: 768.89px) {
            line-height: 0.7rem;
            padding: 0 0.3rem;
            font-size: 0.45rem;
          }
        }
      }
      .anchor {
        position: absolute;
        display: flex;
        align-items: flex-end;
        font-size: 0.75rem;
        line-height: 1rem;
        @media (max-width: 768.89px) {
          font-size: 0.5rem;
        }
        &:hover {
          color: #36edff;
          .icon {
            background-image: url('~@/assets/home/welcome-default-aa.png');
          }
          .line,
          .text {
            border-color: #36edff;
          }
        }
        .icon {
          display: block;
          width: 0.7rem;
          height: 0.7rem;
          background: url('~@/assets/home/welcome-default-a.png') 0 0 no-repeat;
          background-size: 100%;
        }
        .text {
          flex: 1;
          text-align: right;
          border-bottom: 0.05rem dashed #fff;
        }
        .line {
          border-right: 0.05rem dashed #fff;
          border-bottom: 0.05rem dashed #fff;
        }
        &.anchor-1 {
          top: 14.6%;
          left: 56.65%;
          right: 10%;
        }
        &.anchor-2 {
          top: 59.5%;
          left: 49.5%;
          right: 6%;
          height: 30%;
          .line {
            width: 10%;
            height: 100%;
          }
          .text {
            margin-bottom: 46%;
          }
        }
        &.anchor-3 {
          top: 78.5%;
          left: 34.5%;
          right: 7%;
          height: 16%;
          .line {
            width: 41%;
            height: 100%;
          }
          .text {
            margin-bottom: 18.5%;
          }
        }
      }
    }
    .screen-progress {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 1.1rem;
      color: #ef8043;
      .loading {
        margin: 0 auto;
        width: 40%;
      }
      .text {
        margin-top: 0;
      }
    }
    .screen-text {
      position: relative;
      display: flex;
      flex-direction: column;
      padding: 1.5rem 0.3rem 1.8rem 1.5rem;
      height: 100%;
      line-height: 2;
      box-sizing: border-box;
      font-size: 0.7rem;
      @media (max-width: 768.89px) {
        line-height: 1.5;
      }
      .inner {
        padding-right: 1.2rem;
        flex: 1;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 0.1rem;
        }
      }
      .page {
        &.hide {
          display: none;
        }
        @media (max-width: 768.89px) {
          &.hide {
            display: block;
          }
        }
      }
      .title {
        margin: 0 0 0.3rem;
        font-size: 1rem;
        color: #f0a730;
      }
      p {
        margin: 0.5rem 0;
      }
      .page-btns {
        margin-right: 1rem;
        line-height: 1.2;
        text-align: right;
      }
      .page-btn {
        margin: 0 0.5rem;
        font-family: 'Pixeboy';
        font-size: 1rem;
        cursor: pointer;
        @mixin btn-icon {
          display: inline-block;
          width: 0.65rem;
          height: 0.55rem;
          background: url('~@/assets/home/welcome-btn.png') 0 0 no-repeat;
          background-size: 100%;
          vertical-align: middle;
        }
        &.icon-left::before {
          content: '';
          @include btn-icon();
          margin-right: 0.4rem;
        }
        &.icon-right::after {
          content: '';
          @include btn-icon();
          margin-left: 0.4rem;
          transform: rotate(180deg);
        }
        &:hover {
          color: #f0a730;
          &::before,
          &::after {
            background-image: url('~@/assets/home/welcome-btn-a.png');
          }
        }
        &.btn-back {
          position: absolute;
          top: 0.5rem;
          right: 1.5rem;
        }
        @media (max-width: 768.89px) {
          display: none;
        }
      }
    }
  }
  .nft-operation {
    position: absolute;
    top: 84%;
    left: 12.5%;
    width: 53%;
    height: 14%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .nft-last {
    padding: 0.3rem 0.8rem 0.3rem 0.5rem;
    min-width: 7rem;
    height: 3rem;
    line-height: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: url('~@/assets/home/welcome-number.gif') 0 0 no-repeat;
    background-size: 100% 100%;
    border-radius: 0.5rem;
    @media (max-width: 768.89px) {
      min-width: 5.1rem;
      height: 2.2rem;
    }

    .num {
      height: 1.6rem;
      font-family: 'Radioland';
      font-size: 2rem;
      color: #36edff;
      vertical-align: middle;
      @media (max-width: 768.89px) {
        height: 1rem;
        font-size: 1.6rem;
      }
    }
    .text {
      margin-left: 0.2rem;
      height: 1.3rem;
      font-family: 'Radioland-Slim';
      font-size: 0.7rem;
      color: #a604af;
      vertical-align: middle;
      @media (max-width: 768.89px) {
        height: 1rem;
        font-size: 0.6rem;
      }
    }
  }
  .nft-mint {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: 'Cansiparane-Bold';
    cursor: pointer;
    &:hover {
      .icon {
        transform: scale(1.05);
        animation: none;
      }
    }
    .icon {
      width: 4.5rem;
      height: 4.5rem;
      background: url('~@/assets/home/welcome-mint-a.png') 0 0 no-repeat;
      background-size: 100%;
      animation: 1s linear infinite twinkle-1;
      @media (max-width: 768.89px) {
        width: 3.6rem;
        height: 3.6rem;
      }
    }
    .text {
      line-height: 1.15;
      font-size: 0.6rem;
      color: #ffaa00;
      .big {
        font-size: 1.1rem;
      }
    }
    .focus {
      position: absolute;
      top: -50%;
      left: 21%;
      height: 50%;
    }
    @keyframes twinkle-1 {
      0% {
        background-image: url('~@/assets/home/welcome-mint.png');
      }
      50% {
        background-image: url('~@/assets/home/welcome-mint-a.png');
      }
      100% {
        background-image: url('~@/assets/home/welcome-mint.png');
      }
    }
  }
  .welcome-2 {
    position: absolute;
    top: 14.78%;
    left: 3.8%;
    width: 15.625%;
    padding-top: 7.91%;
    cursor: pointer;
    background: url('~@/assets/home/welcome-2.png') 0 0 no-repeat;
    background-size: 100%;
    animation: twinkle-2 1s linear infinite both;
    &:hover {
      background-image: url('~@/assets/home/welcome-2a.png');
      animation: none;
    }
    @keyframes twinkle-2 {
      0% {
        background-image: url('~@/assets/home/welcome-2.png');
      }
      50% {
        background-image: url('~@/assets/home/welcome-2a.png');
      }
    }
  }
  .welcome-3 {
    position: absolute;
    top: 39.25%;
    left: 79.7%;
    width: 20.5%;
    padding-top: 20.5%;
    cursor: pointer;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .normal {
      display: block;
    }
    .active {
      display: none;
    }
    &:hover {
      .normal {
        display: none;
      }
      .active {
        margin: 5.6% 0 0 8.65%;
        width: 100%;
        display: block;
      }
    }
  }
  .welcome-4 {
    position: absolute;
    z-index: 10;
    top: 93.6%;
    left: 65.15%;
    width: 13.671875%;
    padding-top: 39.35546%;
    overflow: hidden;
    transform: translateZ(0);
    animation: 5s steps(30) print;
    &.hide {
      display: none;
    }
    @keyframes print {
      0% {
        padding-top: 0;
      }
      100% {
        padding-top: 39.35546%;
      }
    }
    .bg {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
  .nft-id {
    position: absolute;
    margin-bottom: 9%;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 1.3;
    font-size: 2.1rem;
    color: #000;
    @media (max-width: 768.89px) {
      font-size: 1.4rem;
    }
    small {
      font-size: 0.7em;
    }
    .barcode {
      display: block;
      margin: 0 auto;
      width: 86%;
      max-height: 2.25rem;
    }
  }
  @mixin welcome-5 {
    position: absolute;
    display: block;
    width: 4.785%;
    padding-top: 2.832%;
    background: url('~@/assets/home/welcome-5.png') bottom center no-repeat;
    background-size: 100%;
    cursor: pointer;
    // animation: 1s steps(1) infinite welcome-5a;
    // @keyframes welcome-5a {
    //   0% {
    //     background-image: url('~@/assets/home/welcome-5.png');
    //   }
    //   50% {
    //     background-image: url('~@/assets/home/welcome-5a.png');
    //   }
    // }
    &.active {
      background-image: url('~@/assets/home/welcome-5a.png');
    }
  }
  .welcome-5-1 {
    @include welcome-5();
    top: 77.7%;
    left: 26%;
  }
  .welcome-5-2 {
    @include welcome-5();
    top: 77.7%;
    left: 32%;
    // animation-delay: 500ms;
  }
  @mixin welcome-6 {
    position: absolute;
    display: block;
    width: 6.738%;
    padding-top: 6.542%;
    background: url('~@/assets/home/welcome-6.png') bottom center no-repeat;
    background-size: 100%;
    animation: 1s steps(1) infinite welcome-6a;
    @keyframes welcome-6a {
      0% {
        background-image: url('~@/assets/home/welcome-6.png');
      }
      50% {
        background-image: url('~@/assets/home/welcome-6a.png');
      }
    }
  }
  .welcome-6-1 {
    @include welcome-6();
    top: 75.3%;
    left: 63.8%;
  }
  .welcome-6-2 {
    @include welcome-6();
    top: 76%;
    left: 67.91%;
    animation-delay: 250ms;
  }
  .welcome-6-3 {
    @include welcome-6();
    top: 73.6%;
    left: 67.32%;
    animation-delay: 750ms;
  }
  .welcome-6-4 {
    @include welcome-6();
    top: 75%;
    left: 72%;
    animation-delay: 500ms;
  }
  .welcome-7 {
    position: absolute;
    top: 67.9%;
    left: 56%;
    width: 5.273%;
    padding-top: 8.984%;
    background: url('~@/assets/home/welcome-7.png') bottom center no-repeat;
    background-size: 100%;
  }
}
</style>
