<template>
  <div class="nfts-dialog" v-if="visible">
    <div
      :class="['bg', animateIn ? 'fadeIn' : 'fadeOut']"
      @click.self="onHide"
      @touchmove.prevent.stop
    ></div>
    <img
      :class="['image', animateIn ? 'slideInFromLeft' : 'slideDown']"
      :src="image || require('@/assets/home/nfts-dialog-1.png')"
    />
    <main :class="['nfts-main', animateIn ? 'slideInFromRight' : 'slideDown']">
      <a class="close" @click="onHide"></a>
      <div :class="['content']">
        <slot></slot>
      </div>
      <div class="operate" :style="pageCount <= 1 ? 'visibility: hidden;' : ''">
        <a v-if="page > 1" class="btn-page page-prev" @click="onPrev"></a>
        <a v-if="page < pageCount" class="btn-page page-next" @click="onNext"></a>
      </div>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    image: String,
    pageCount: Number,
    page: Number,
  },
  data() {
    return {
      animateIn: false,
      visible: false,
    }
  },
  methods: {
    show() {
      this.visible = true
      this.animateIn = true
    },
    hide() {
      this.animateIn = false
      setTimeout(() => {
        this.visible = false
      }, 500)
    },
    onHide() {
      this.hide()
    },

    onPrev() {
      if (this.page <= 1) return
      this.$emit('prev')
    },
    onNext() {
      if (this.page >= this.pageCount) return
      this.$emit('next')
    },
  },
}
</script>

<style lang="scss" scoped>
.nfts-dialog {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  &::before {
    content: '';
    display: block;
    flex: 1;
  }
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.6);
    transform: translateZ(0);
    &.fadeIn {
      animation: 500ms linear fadeIn;
    }
    &.fadeOut {
      animation: 500ms linear fadeOut;
    }
  }
  .nfts-main {
    position: absolute;
    left: 3%;
    right: 3%;
    bottom: 1rem;
    z-index: 10;
    padding: 2rem 1rem;
    line-height: 1.8;
    text-align: left;
    color: #fff;
    font-size: 0.8rem;
    border: 0.15rem solid transparent;
    border-radius: 0.5rem;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(0, #000, #000), linear-gradient(0, #12d6df, #f70fff);
    box-shadow: 0 0 3rem 0.5rem #0119c1, 0 0 1.5rem #0119c1, 0 0 3rem 0.5rem inset #0119c1,
      0 0 1.5rem inset #0119c1;
    &.slideInFromRight {
      animation: slideInFromRight 400ms linear 100ms both, fadeIn 400ms linear 100ms both;
    }
    // &.slideOutToRight {
    //   animation: slideOutToRight 400ms linear 100ms both, fadeOut 400ms linear 100ms both;
    // }
    // &.slideUp {
    //   animation: slideUp 400ms linear 100ms both;
    // }
    &.slideDown {
      animation: slideDown 400ms linear 100ms both;
    }
  }
  .close {
    position: absolute;
    top: -4rem;
    right: 0;
    display: block;
    width: 3rem;
    height: 3rem;
    background: url('~@/assets/home/nfts-dialog-close.png') center center no-repeat;
    background-size: 1.2rem;
    cursor: pointer;
  }
  .content {
    padding: 0 1.5rem;
    height: 11rem;
    overflow: auto;
    @media (max-width: 768.89px) {
      height: 14rem;
    }
  }
  .operate {
    height: 2.5rem;
    text-align: right;
    .btn-page {
      margin: 0 0.25rem;
      padding: 0 0.5rem;
      display: inline-block;
      width: 3rem;
      height: 2.5rem;
      background: url('~@/assets/home/nfts-dialog-arrow.png') 0 0 no-repeat;
      background-size: 100%;
      &:hover {
        background-image: url('~@/assets/home/nfts-dialog-arrow-a.png');
      }
    }
    .page-next {
      transform: rotateY(180deg);
    }
  }

  .image {
    position: absolute;
    bottom: 19rem;
    left: 5%;
    width: 10.8rem;
    @media (max-width: 768.89px) {
      bottom: 22rem;
    }
    &.slideInFromLeft {
      animation: slideInFromLeft 400ms linear 100ms both, fadeIn 400ms linear 100ms both;
    }
    // &.slideOutToLeft {
    //   animation: slideOutToLeft 400ms linear 100ms both, fadeOut 400ms linear 100ms both;
    // }
    // &.slideUp {
    //   animation: slideUp 400ms linear 100ms both;
    // }
    &.slideDown {
      animation: slideDown 400ms linear 100ms both;
    }
  }

  @keyframes slideUp {
    0% {
      transform: translateY(2000px) translateZ(0);
    }
    100% {
      transform: translateZ(0);
    }
  }
  @keyframes slideDown {
    0% {
      transform: translateZ(0);
    }
    100% {
      transform: translateY(2000px) translateZ(0);
    }
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%) translateZ(0);
    }
    100% {
      transform: translateZ(0);
    }
  }
  @keyframes slideOutToRight {
    0% {
      transform: translateZ(0);
    }
    100% {
      transform: translateX(100%) translateZ(0);
    }
  }
  @keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%) translateZ(0);
    }
    100% {
      transform: translateZ(0);
    }
  }
  @keyframes slideOutToLeft {
    0% {
      transform: translateZ(0);
    }
    100% {
      transform: translateX(-100%) translateZ(0);
    }
  }
}
</style>
