<template>
  <div ref="container"></div>
</template>

<script>
import lottie from 'lottie-web'
import data from './data.json'

export default {
  mounted() {
    lottie.loadAnimation({
      container: this.$refs.container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData: data,
    })
  },
}
</script>

<style lang="scss"></style>
