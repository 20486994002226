export default [
  {
    name: 'twitter',
    link: 'https://twitter.com/GameApeFC',
    image: require('@/assets/home/social-twitter.png'),
    image2: require('@/assets/nav/social-twitter.png'),
  },
  {
    name: 'discord',
    link: 'https://discord.gg/uwJxmcaPap',
    image: require('@/assets/home/social-discord.png'),
    image2: require('@/assets/nav/social-discord.png'),
  },
  {
    name: 'opensea',
    link: '',
    image: require('@/assets/home/social-3.png'),
    image2: require('@/assets/nav/social-opensea.png'),
  },
]
