<template>
  <router-view />
</template>

<script>
import * as ethWallet from '@/web3/wallet-eth'
import * as suiWallet from '@/web3/wallet-sui'
import emitter from '@/common/js/emitter'
import { transformAddress } from '@/common/js/utils'

export default {
  data() {
    return {
      walletAddr: '',
    }
  },
  computed: {
    vWalletAddr() {
      return transformAddress(this.walletAddr)
    },
  },
  methods: {
    async connect(walletName) {
      if (!walletName) {
        walletName = localStorage.getItem('CONNECTED_WALLET')
      }
      localStorage.setItem('CONNECTED_WALLET', walletName)

      if (this._promise) return this._promise

      // this._promise = this._connectEth(walletName)
      this._promise = this._connectSui(walletName)
      const result = await this._promise
      this._promise = null

      return result
    },
    async _connectEth(walletName) {
      let walletAddr = ''
      try {
        walletAddr = await ethWallet.connect(walletName)
        const web3Instance = ethWallet.getWeb3Instance()
        const chainId = await web3Instance.eth.getChainId()
        if (!ethWallet.isSupportedChain(chainId)) {
          alert('Please change your network provider to the Ethereum')
        }
      } catch (err) {
        console.error(err)
      }
      this.walletAddr = walletAddr
      return walletAddr
    },
    async _connectSui(walletName) {
      let walletAddr = ''
      try {
        walletAddr = await suiWallet.connect(walletName)
      } catch (err) {
        console.error(err)
      }
      this.walletAddr = walletAddr
      return walletAddr
    },
  },
  created() {
    ethWallet.bind({
      accountsChanged: async (accounts) => {
        const walletAddr = (accounts && accounts[0]) || ''
        if (walletAddr && this.walletAddr === walletAddr) return
        this.walletAddr = walletAddr
        emitter.emit('accountsChanged')
      },
      chainChanged: (chainId) => {
        if (!ethWallet.isSupportedChain(chainId)) {
          alert('Please change your network provider to the Ethereum')
        }
        window.location.reload()
      },
    })
    suiWallet.bind({
      accountsChanged: async (accounts) => {
        const walletAddr = (accounts && accounts[0]) || ''
        if (walletAddr && this.walletAddr === walletAddr) return
        this.walletAddr = walletAddr
        emitter.emit('accountsChanged')
      },
    })
  },
}
</script>

<style lang="scss">
@import '@/common/css/base.scss';

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
</style>
